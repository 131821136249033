import React, { Fragment, useState, useContext, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import Alert from "react-s-alert";
import { Media } from "react-breakpoints";
import {
  Col,
  Button,
  Row,
  ButtonGroup,
  Table,
  Input,
  Alert as Alert2,
  Card,
  CardBody,
} from "reactstrap";
import Select from "react-select";
import Switch from "react-switch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { api, helpers, UserContext, constants, dateHelpers } from "../utils";
import {
  CollapsibleCardSection,
  TextNotificationModal,
  DocumentUpload,
  OrgTypeahead,
  SHBSpinner,
  ChecklistImageUpload,
  AddressAutocomplete,
  EstimatedTimeOfDay,
  JobSummaryModal,
  DocumentUploader,
  JobDocumentUpload,
} from "../components";
import { DesktopContainer } from "../layouts";

const { JOB_STATUS_NAMES } = constants;

const emptyJob = {
  id: 0,
  jobType: { value: 6, label: "RTO Delivery" },
  jobStatus: { value: 1, label: JOB_STATUS_NAMES.OPEN },
  orgId: null,
  requestedByDate: null,
  scheduledAt: null,
  scheduledBy: null,
  estimatedPickupAt: null,
  estimatedDropoffAt: null,
  estimatedPickupTimeOfDay: "",
  estimatedDropoffTimeOfDay: "",
  actualPickupAt: null,
  actualDropoffAt: null,
  reservedByOrg: null,
  reservedAt: null,
  cancelledAt: null,
  cancelledBy: null,
  cancellationReason: null,
  parentJobId: null,
  referenceNumber: "",
  createdByOrgPrimaryContactFirstName: "",
  createdByOrgPrimaryContactLastName: "",
  createdByOrgPrimaryContactNumber: "",
  createdByOrgEmail: "",
  compensationAmount: "0",
  customerFeeCollectedAt: null,
  unit: null,
  manufacturer: "",
  serialNumber: "",
  heightInFeet: "",
  widthInFeet: "",
  lengthInFeet: "",
  unitType: { value: 4, label: "Utility" },
  unitStyle: null,
  unitMaterial: { value: 4, label: "Wood" },
  wallColor: { value: 1, label: "Blue" },
  trimColor: { value: 1, label: "Blue" },
  roofColor: { value: 1, label: "Blue" },
  otherRoofColor: "",
  otherWallColor: "",
  otherTrimColor: "",
  unitComment: "",
  useExistingUnit: false, // not persisted, just used locally,
  ownerFirstName: "",
  ownerLastName: "",
  ownerEmail: "",
  ownerPhoneNumber: "",
  cinders: "",
};

const emptyJobFee = {
  id: 0,
  feeType: null,
  amount: "0.00",
  description: "",
};

const emptyComment = {
  id: 0,
  comment: "",
  isPrivate: false,
};

export default function Job(props) {
  const userCtx = useContext(UserContext);
  const currentUser = userCtx.currentUser;
  const userOrgId = userCtx.currentUser.currentOrgId;
  const isCompanyUser = userCtx.currentUser.isCompanyUser;
  const isHaulerFirm =
    userCtx.currentUser.isHaulerAdminUser ||
    userCtx.currentUser.isDispatcherUser;
  const isHaulerUser = userCtx.currentUser.isHaulerUser;

  const jobId =
    props &&
    props.computedMatch &&
    props.computedMatch.params &&
    props.computedMatch.params.id
      ? parseInt(props.computedMatch.params.id, 10)
      : 0;

  const [job, setJob] = useState({
    ...emptyJob,
    createdByOrgPrimaryContactFirstName: userCtx.currentUser.firstName,
    createdByOrgPrimaryContactLastName: userCtx.currentUser.lastName,
    createdByOrgPrimaryContactNumber: userCtx.currentUser.phoneNumber,
    createdByOrgEmail: userCtx.currentUser.email,
  });
  const [jobFees, setJobFees] = useState(null);
  const [totalFees, setTotalFees] = useState(0);
  const [newFee, setNewFee] = useState(null);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState(null);
  const [draftMode, setDraftMode] = useState(false);
  const [jobOpen, setJobOpen] = useState(false);
  const [jobReserved, setJobReserved] = useState(false);
  const [jobScheduled, setJobScheduled] = useState(false);
  const [jobInProgress, setJobInProgress] = useState(false);
  const [jobComplete, setJobComplete] = useState(false);
  const [jobCancelled, setJobCancelled] = useState(false);
  const [showTextModal, setShowTextModal] = useState(null);
  const [driverOptions, setDriverOptions] = useState([]);
  const [orgRigs, setOrgRigs] = useState([]);
  const [message, setMessage] = useState(null);
  const [jobTypeList, setJobTypeList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [unitTypeList, setUnitTypeList] = useState([]);
  const [unitStyleList, setUnitStyleList] = useState([]);
  const [unitMaterialList, setUnitMaterialList] = useState([]);
  const [unitColorList, setUnitColorList] = useState([]);
  const [unitList, setUnitList] = useState([]);
  const [orgLocations, setOrgLocations] = useState([]);
  const [orgPrimaryUser, setOrgPrimaryUser] = useState(null);
  const [redirect, setRedirect] = useState(false);
  const [isJobOwner, setIsJobOwner] = useState(false);
  const [loading, setLoading] = useState(false);
  const [jobRigs, setJobRigs] = useState([]);
  const [orgDefaultChecklistItems, setOrgDefaultChecklistItems] = useState([]);
  const [jobChecklistItems, setJobChecklistItems] = useState([]);
  const [sendHaulerInvite, setSendHaulerInvite] = useState(false);
  const [jobChecklistResponses, setJobChecklistResponses] = useState([]);
  const [checklistAnswers, setChecklistAnswers] = useState({});
  const [jobMileageAndFuelCostSummary, setJobMileageAndFuelCostSummary] =
    useState(null);
  const [showMileageModal, setShowMileageModal] = useState(false);
  const [showFuelCostsModal, setShowFuelCostsModal] = useState(false);
  const [mileages, setMileages] = useState([]);
  const [fuelCosts, setFuelCosts] = useState([]);
  const [typeaheadFromAddress, setTypeaheadFromAddress] = useState("");
  const [typeaheadToAddress, setTypeaheadToAddress] = useState("");
  const [fromAddress, setFromAddress] = useState({
    fromAddress1: "",
    fromAddress2: "",
    fromCity: "",
    fromState: null,
    fromZipCode: "",
    pickupLat: null,
    pickupLong: null,
  });
  const [toAddress, setToAddress] = useState({
    toAddress1: "",
    toAddress2: "",
    toCity: "",
    toState: null,
    toZipCode: "",
    dropoffLat: null,
    dropoffLong: null,
  });
  const [newDocumentsToUpload, setNewDocumentsToUpload] = useState([]);

  const getJobDocuments = () =>
    api.fetch(`Job/GetJobDocuments/${jobId}`).then((r) => {
      const jobEdit = Object.assign({}, job);
      jobEdit.documents = r.data;
      setJob(jobEdit);
    });

  function showMileages() {
    api.fetch(`Job/GetJobMileages/${jobId}`).then((r) => {
      if (r && r.data && r.data.length && r.data.length > 0) {
        setMileages(
          r.data.map((x) => {
            return {
              ...x,
              display: `${x.miles} Miles - ${x.stateAbbr}`,
              date: `${dateHelpers.dateTimeFormat(x.createdAt)}`,
            };
          })
        );
        setShowMileageModal(true);
      }
    });
  }

  function showFuelCosts() {
    api.fetch(`Job/GetJobFuelCosts/${jobId}`).then((r) => {
      if (r && r.data && r.data.length && r.data.length > 0) {
        setFuelCosts(
          r.data.map((x) => {
            return {
              ...x,
              display: `${x.fuelCostDisplay} - ${x.stateAbbr}`,
              date: `${dateHelpers.dateTimeFormat(x.createdAt)}`,
            };
          })
        );
        setShowFuelCostsModal(true);
      }
    });
  }

  function getOrgDefaultJobFees(jobTypeId) {
    let orgIds = []; // ???
    orgIds.push(userOrgId);
    let payload = {
      sortField: "Id",
      sortDirection: "asc",
      activeOnly: true,
      orgIds: orgIds,
      jobTypeId: jobTypeId,
    };
    return api.post("Job/GetOrgFeeDefaults", payload).then((r) => ({
      orgDefaultJobFees: _.map(r.data, (item, index) => {
        return {
          amount: item.amountDecimal,
          feeType: helpers.resolveValue(null, item.feeTypeId, item.feeTypeName),
          local_id: -1 * (index + 1),
          description: "Default Fee",
        };
      }),
    }));
  }

  function getOrgDefaultChecklistItems(jobTypeId) {
    let payload = {
      OrgId: userOrgId,
      JobTypeId: jobTypeId,
    };
    return api.post("Job/GetChecklistItems", payload).then((r) => ({
      orgDefaultChecklistItems: _.map(r.data, (item, index) => {
        return {
          id: item.id,
          value: item.id,
          label: item.prompt,
          name: item.name,
          prompt: item.prompt,
          checkListItemTypeName: item.checkListItemTypeName,
          isRequired: item.isRequired,
          local_id: -1 * (index + 1),
        };
      }),
    }));
  }

  useEffect(() => {
    // load job data
    setLoading(true);

    const getJob = () =>
      api.fetch(`Job/GetJob/${jobId}`).then((r) => ({
        job: r.data,
      }));

    const getHaulerDrivers = () => {
      return api.post(`Org/GetOrgDrivers/${userOrgId}`).then((r) => ({
        driverOptions: _.map(r.data, (d) => {
          return {
            value: d.id,
            label: d.name,
          };
        }),
      }));
    };

    const getJobChecklistItems = () => {
      return api.fetch(`Job/GetJobChecklistItems/${jobId}`).then((r) => ({
        jobChecklistItems: _.map(r.data, (item, index) => {
          return {
            ...item,
            local_id: -1 * (index + 1),
          };
        }),
      }));
    };

    const getJobChecklistResponses = () => {
      return api.fetch(`Job/getJobChecklistResponses/${jobId}`).then((r) => ({
        jobChecklistResponses: r.data,
      }));
    };

    const getJobComments = () => {
      let payload = {
        sortField: "CREATEDAT",
        sortDirection: "asc",
        activeOnly: true,
        jobIds: [jobId],
        orgId: userOrgId,
      };
      return api.post("Job/GetJobComments", payload).then((r) => ({
        jobComments: _.map(r.data, (item, index) => {
          return {
            id: item.id,
            comment: item.comment,
            isPrivate: item.privateToOrgId ? true : false, // FIX: will not work if privateOrgId is 0
            local_id: -1 * (index + 1),
          };
        }),
      }));
    };

    const getJobFees = () => {
      let payload = {
        sortField: "Id",
        sortDirection: "asc",
        activeOnly: true,
        jobIds: [jobId],
      };
      return api.post("Job/GetJobFees", payload).then((r) => ({
        jobFees: _.map(r.data, (item, index) => {
          return {
            id: item.id,
            amount: item.amount,
            feeType: helpers.resolveValue(
              null,
              item.feeTypeId,
              item.feeTypeName
            ),
            local_id: -1 * (index + 1),
            description: item.description,
          };
        }),
      }));
    };

    const getJobMileageAndFuelCostSummary = () =>
      api.fetch(`Job/GetJobMileageAndFuelCostSummary/${jobId}`).then((r) => ({
        summary: r.data,
      }));

    const getJobRigs = () => {
      return api.fetch(`Job/GetJobRigTypes/${jobId}`).then((r) => ({
        jobRigs: _.map(r.data, (jr) => {
          return {
            value: jr.id,
            label: jr.rigTypeName,
          };
        }),
      }));
    };

    const getOrgLocations = () => {
      let orgIds = []; // ???
      orgIds.push(userOrgId);
      let payload = {
        sortField: "Id",
        sortDirection: "asc",
        activeOnly: true,
        orgIds: orgIds,
      };
      return api.post("Job/GetOrgLocations", payload).then((r) => ({
        orgLocations: _.map(
          _.uniqBy(r.data, (r) => r.streetAddress1),
          (item, index) => {
            return {
              value: item.id,
              label: `${item.streetAddress1}, ${
                item.streetAddress2 ? item.streetAddress2 + ", " : ""
              } ${item.city ? item.city + ", " : ""} ${
                item.stateName ? item.stateName : ""
              } ${item.zipCode ? item.zipCode : ""} `,
              streetAddress1: helpers.nullableString(item.streetAddress1),
              streetAddress2: helpers.nullableString(item.streetAddress2),
              city: helpers.nullableString(item.city),
              stateId: item.stateId,
              stateName: helpers.nullableString(item.stateName),
              zipCode: helpers.nullableString(item.zipCode),
              locationType: helpers.resolveValue(
                null,
                item.locationTypeId,
                item.locationTypeName
              ),
              locationTypeId: item.locationTypeId,
              locationTypeName: item.locationTypeName,
            };
          }
        ),
      }));
    };

    const getRigs = () => {
      return api.post(`Org/GetOrgRigs/${userOrgId}`).then((r) => ({
        orgRigs: _.map(r.data, (r) => {
          return {
            value: r.id,
            label: r.rigTypeName,
          };
        }),
      }));
    };

    const getUnits = () => {
      let orgIds = []; // ???
      orgIds.push(userOrgId);
      let payload = {
        sortField: "Id",
        sortDirection: "asc",
        activeOnly: true,
        orgIds: orgIds,
      };
      return api.post("Unit/GetSelectList", payload).then((r) => ({
        units: r.data,
      }));
    };

    let apiCalls = [];
    if (jobId > 0) {
      apiCalls.push(getJob());
      apiCalls.push(getJobFees());
      apiCalls.push(getJobComments());
      apiCalls.push(getJobRigs());
      apiCalls.push(getJobChecklistItems());
      apiCalls.push(getJobChecklistResponses());
      apiCalls.push(getJobMileageAndFuelCostSummary());
    }
    apiCalls.push(api.getJobTypes());
    apiCalls.push(api.getStates());
    apiCalls.push(api.getUnitTypes());
    apiCalls.push(api.getUnitStyles());
    apiCalls.push(api.getUnitMaterials());
    apiCalls.push(api.getUnitColors());
    if (isCompanyUser) {
      apiCalls.push(getUnits());
      apiCalls.push(getOrgLocations());
      apiCalls.push(getOrgDefaultJobFees(emptyJob.jobType.value));
      apiCalls.push(getOrgDefaultChecklistItems(emptyJob.jobType.value));
    }
    if (isHaulerUser) {
      apiCalls.push(getRigs());
      apiCalls.push(getHaulerDrivers());
    }
    Promise.all(apiCalls)
      .then((arrayResults) => {
        let aggResults = {};
        _.each(arrayResults, (x) => Object.assign(aggResults, x));
        const tempJob = aggResults.job
          ? Object.assign({}, aggResults.job)
          : null;
        if (tempJob) {
          let formattedJob = {
            id: tempJob.id,
            orgId: tempJob.orgId,
            orgName: tempJob.orgName,
            requestedByDate: dateHelpers.dateFormatForClient(
              tempJob.requestedByDate
            ),
            scheduledAt: tempJob.scheduledAt,
            scheduledBy: tempJob.scheduledBy,
            driver: helpers.resolveValue(
              null,
              tempJob.driverId,
              tempJob.driverFullName
            ),
            estimatedPickupAt: tempJob.estimatedPickupAt
              ? dateHelpers.dateFormatForClient(tempJob.estimatedPickupAt) // new Date(tempJob.estimatedPickupAt)
              : null,
            estimatedDropoffAt: tempJob.estimatedDropOffAt
              ? dateHelpers.dateFormatForClient(tempJob.estimatedDropOffAt) // new Date(tempJob.estimatedDropOffAt)
              : null,
            estimatedPickupTimeOfDay: helpers.nullableString(
              tempJob.estimatedPickupTimeOfDay
            ),
            estimatedDropoffTimeOfDay: helpers.nullableString(
              tempJob.estimatedDropOffTimeOfDay
            ),
            actualPickupAt: tempJob.actualPickupAt
              ? new Date(tempJob.actualPickupAt)
              : new Date(),
            actualPickupAtTime: tempJob.actualPickupAt
              ? tempJob.actualPickupAtTime
              : "12:00", // need to parse out actualPickupAtTime from actualPickupAt datetime
            actualDropoffAt: tempJob.actualDropoffAt
              ? new Date(tempJob.actualDropoffAt)
              : new Date(),
            actualDropoffAtTime: tempJob.actualDropoffAt
              ? tempJob.actualDropoffAtTime
              : "12:00", // need to parse out actualDropoffAtTime from actualDropoffAt datetime
            reservedAt: tempJob.reservedAt,
            cancelledAt: tempJob.cancelledAt,
            cancelledBy: tempJob.cancelledBy,
            cancellationReason: helpers.nullableString(
              tempJob.cancellationReason
            ),
            parentJobId: null,
            referenceNumber: helpers.nullableString(tempJob.referenceNumber),
            createdByOrgPrimaryContactFirstName: helpers.nullableString(
              tempJob.createdByOrgPrimaryContactFirstName
            ),
            createdByOrgPrimaryContactLastName: helpers.nullableString(
              tempJob.createdByOrgPrimaryContactLastName
            ),
            createdByOrgPrimaryContactNumber: helpers.nullableString(
              tempJob.createdByOrgPrimaryContactNumber
            ),
            createdByOrgEmail: helpers.nullableString(
              tempJob.createdByOrgEmail
            ),
            ownerFirstName: helpers.nullableString(tempJob.ownerFirstName),
            ownerLastName: helpers.nullableString(tempJob.ownerLastName),
            ownerEmail: helpers.nullableString(tempJob.ownerEmail),
            ownerPhoneNumber: helpers.nullableString(tempJob.ownerPhoneNumber),
            compensationAmount: tempJob.compensationAmount,
            customerFeeCollectedAt: tempJob.customerFeeCollectedAt,
            unit: helpers.resolveValue(
              null,
              tempJob.unitId,
              tempJob.unitSerialNumber
            ),
            manufacturer: helpers.nullableString(tempJob.manufacturer),
            serialNumber: helpers.nullableString(tempJob.serialNumber),
            heightInFeet: tempJob.heightInFeet.toFixed(2),
            widthInFeet: tempJob.widthInFeet.toFixed(2),
            lengthInFeet: tempJob.lengthInFeet.toFixed(2),
            unitComment: helpers.nullableString(tempJob.unitComment),
            jobType: helpers.resolveValue(
              null,
              tempJob.jobTypeId,
              tempJob.jobTypeName
            ),
            jobStatus: helpers.resolveValue(
              null,
              tempJob.jobStatusId,
              tempJob.jobStatusName
            ),
            unitType: helpers.resolveValue(
              null,
              tempJob.unitTypeId,
              tempJob.unitTypeName
            ),
            unitStyle: helpers.resolveValue(
              null,
              tempJob.unitStyleId,
              tempJob.unitStyleName
            ),
            unitMaterial: helpers.resolveValue(
              null,
              tempJob.unitMaterialId,
              tempJob.unitMaterialName
            ),
            wallColor: helpers.resolveValue(
              null,
              tempJob.wallColorId,
              tempJob.wallColorName
            ),
            trimColor: helpers.resolveValue(
              null,
              tempJob.trimColorId,
              tempJob.trimColorName
            ),
            roofColor: helpers.resolveValue(
              null,
              tempJob.roofColorId,
              tempJob.roofColorName
            ),
            otherRoofColor: helpers.nullableString(tempJob.otherRoofColor),
            otherWallColor: helpers.nullableString(tempJob.otherWallColor),
            otherTrimColor: helpers.nullableString(tempJob.otherTrimColor),
            useExistingUnit: false, // not persisted, used locally
            documents: tempJob.documents,
            reservedByOrg: helpers.resolveValue(
              null,
              tempJob.reservedByOrgId,
              tempJob.reservedByOrgName
            ),
            assignedToOrg: helpers.resolveValue(
              null,
              tempJob.assignedToOrgId,
              tempJob.assignedToOrgName
            ),
            reservedByOrgPrimaryContactName: helpers.nullableString(
              tempJob.reservedByOrgPrimaryContactName
            ),
            assignedToOrgPrimaryContactName: helpers.nullableString(
              tempJob.assignedToOrgPrimaryContactName
            ),
            reservedByOrgPrimaryContactNumber: helpers.nullableString(
              tempJob.reservedByOrgPrimaryContactNumber
            ),
            assignedToOrgPrimaryContactNumber: helpers.nullableString(
              tempJob.assignedToOrgPrimaryContactNumber
            ),
            isExtreme: tempJob.isExtreme,
          };
          setJob(formattedJob);
          setFromAddress({
            fromAddress1: helpers.nullableString(tempJob.fromStreetAddress1),
            fromAddress2: helpers.nullableString(tempJob.fromStreetAddress2),
            fromCity: helpers.nullableString(tempJob.fromCity),
            fromZipCode: helpers.nullableString(tempJob.fromZipCode),
            fromState: helpers.resolveValue(
              null,
              tempJob.fromStateId,
              tempJob.fromStateName
            ),
            pickupLat: tempJob.pickupLat,
            pickupLong: tempJob.pickupLong,
          });
          setToAddress({
            toAddress1: helpers.nullableString(tempJob.toAddress1),
            toAddress2: helpers.nullableString(tempJob.toAddress2),
            toCity: helpers.nullableString(tempJob.toCity),
            toZipCode: helpers.nullableString(tempJob.toZipCode),
            toState: helpers.resolveValue(
              null,
              tempJob.toStateId,
              tempJob.toStateName
            ),
            dropoffLat: tempJob.dropoffLat,
            dropoffLong: tempJob.dropoffLong,
          });
        } else {
          if (aggResults.orgPrimaryUser) {
            setJob({
              ...job,
              createdByOrgPrimaryContactFirstName:
                aggResults.orgPrimaryUser.firstName,
              createdByOrgPrimaryContactLastName:
                aggResults.orgPrimaryUser.lastName,
            });
          }
        }
        setJobTypeList(aggResults.jobTypes);
        setStateList(aggResults.states);
        setUnitTypeList(aggResults.unitTypes);
        setUnitStyleList(aggResults.unitStyles);
        setUnitMaterialList(aggResults.unitMaterials);
        setUnitColorList(aggResults.unitColors);
        setUnitList(aggResults.units);
        setOrgLocations(aggResults.orgLocations);
        setOrgRigs(aggResults.orgRigs);
        setDriverOptions(aggResults.driverOptions);
        setOrgDefaultChecklistItems(aggResults.orgDefaultChecklistItems);
        setJobMileageAndFuelCostSummary(aggResults.summary);
        if (tempJob) {
          // job already exists
          setJobFees(aggResults.jobFees);
          setJobChecklistItems(aggResults.jobChecklistItems);
          setComments(aggResults.jobComments);
          setJobRigs(aggResults.jobRigs);
          setJobChecklistResponses(aggResults.jobChecklistResponses);
        } else {
          // new job
          setJobFees(aggResults.orgDefaultJobFees);
          setOrgPrimaryUser(aggResults.orgPrimaryUser);
          let defaultJobChecklistItems = _.map(
            aggResults.orgDefaultChecklistItems,
            (item, index) => {
              return {
                checkListItem: {
                  id: item.id,
                  value: item.id,
                  label: item.prompt,
                  name: item.name,
                  prompt: item.prompt,
                  checkListItemTypeName: item.checkListItemTypeName,
                  isRequired: item.isRequired,
                },
                local_id: -1 * (index + 1),
              };
            }
          );
          setJobChecklistItems(defaultJobChecklistItems);
        }
        let initialJobFees = tempJob
          ? aggResults.jobFees
          : aggResults.orgDefaultJobFees;
        let fees = 0;
        if (initialJobFees) {
          _.each(initialJobFees, (f) => {
            fees = fees + Number(f.amount);
          });
          setTotalFees(fees);
        }
      })

      .catch((error) => {
        setMessage({
          flavor: "alert-danger",
          text: "There was an error loading job data.",
        });
      })
      .finally(() => setLoading(false));
  }, [isCompanyUser, isHaulerUser, userOrgId, jobId]);

  useEffect(() => {
    setDraftMode(job && job.id === 0);
    switch (job && job.jobStatus ? job.jobStatus.value : 0) {
      case constants.JOB_STATUS_IDS.OPEN:
        setJobOpen(true);
        setJobReserved(false);
        setJobScheduled(false);
        setJobInProgress(false);
        setJobComplete(false);
        setJobCancelled(false);
        break;
      case constants.JOB_STATUS_IDS.RESERVED:
        setJobOpen(false);
        setJobReserved(true);
        setJobScheduled(false);
        setJobInProgress(false);
        setJobComplete(false);
        setJobCancelled(false);
        break;
      case constants.JOB_STATUS_IDS.SCHEDULED:
        setJobOpen(false);
        setJobReserved(false);
        setJobScheduled(true);
        setJobInProgress(false);
        setJobComplete(false);
        setJobCancelled(false);
        break;
      case constants.JOB_STATUS_IDS.IN_PROGRESS:
        setJobOpen(false);
        setJobReserved(false);
        setJobScheduled(false);
        setJobInProgress(true);
        setJobComplete(false);
        setJobCancelled(false);
        break;
      case constants.JOB_STATUS_IDS.COMPLETE:
        setJobOpen(false);
        setJobReserved(false);
        setJobScheduled(false);
        setJobInProgress(false);
        setJobComplete(true);
        setJobCancelled(false);
        break;
      case constants.JOB_STATUS_IDS.CANCELLED:
        setJobOpen(false);
        setJobReserved(false);
        setJobScheduled(false);
        setJobInProgress(false);
        setJobComplete(false);
        setJobCancelled(true);
        break;
      default:
        break;
    }
    setIsJobOwner(
      job &&
        (!(job.id > 0) ||
          (job.orgId === currentUser.currentOrgId &&
            constants.ADMIN_TASK_ROLE_IDS.includes(
              currentUser.currentRoleId
            )) ||
          currentUser.isAdmin)
    );
  }, [job, currentUser]);

  function onReserveHaulerChange(selection) {
    if (selection && selection.value) {
      api.post(`org/GetOrgDrivers/${selection.value}`).then((r) => {
        if (r && r.data) {
          setDriverOptions(
            _.map(r.data, (d) => {
              d.value = d.id;
              d.label = d.name;
              return d;
            })
          );
          setJob({ ...job, reservedByOrg: selection, driver: null });
          setSendHaulerInvite(true);
        }
      });
    } else {
      setJob({ ...job, reservedByOrg: selection, driver: null });
      setSendHaulerInvite(false);
    }
  }

  function onChangeJobType(selection) {
    if (selection && selection.value) {
      let apiCalls = [];
      apiCalls.push(getOrgDefaultJobFees(selection.value));
      apiCalls.push(getOrgDefaultChecklistItems(selection.value));
      Promise.all(apiCalls)
        .then((arrayResults) => {
          let aggResults = {};
          _.each(arrayResults, (x) => Object.assign(aggResults, x));
          if (
            window.confirm(
              "Overwrite current job fees with default job fees for this new job type?"
            )
          ) {
            let defaultFees = aggResults.orgDefaultJobFees;
            setJobFees(defaultFees);
            setTotalFees(_.sumBy(defaultFees, (f) => Number(f.amount)) || 0);
          }
          // if (window.confirm('Overwrite current job check list items with default job check list items for this new job type?')) {
          setOrgDefaultChecklistItems(aggResults.orgDefaultChecklistItems);
          let defaultJobChecklistItems = _.map(
            aggResults.orgDefaultChecklistItems,
            (item, index) => {
              return {
                // id: 0,
                checkListItem: {
                  // ...item,
                  id: item.id,
                  value: item.id,
                  label: item.prompt,
                  name: item.name,
                  prompt: item.prompt,
                  checkListItemTypeName: item.checkListItemTypeName,
                  isRequired: item.isRequired,
                },
                local_id: -1 * (index + 1),
              };
            }
          );
          setJobChecklistItems(defaultJobChecklistItems);
          // }
        })
        .catch((error) => {
          setMessage({
            flavor: "alert-danger",
            text: "There was an error loading org defaults.",
          });
        });
    }
    const jobEdit = Object.assign({}, job);
    jobEdit.jobType = selection;
    setJob(jobEdit);
  }

  function onChangeSelectedOrgFromAddress(e) {
    setFromAddress({
      ...fromAddress,
      fromAddress1: e.streetAddress1,
      fromAddress2: e.streetAddress2,
      fromCity: e.city,
      fromState: helpers.resolveValue(null, e.stateId, e.stateName),
      fromZipCode: e.zipCode,
    });
  }

  function onChangeSelectedOrgToAddress(e) {
    setToAddress({
      ...toAddress,
      toAddress1: e.streetAddress1,
      toAddress2: e.streetAddress2,
      toCity: e.city,
      toState: helpers.resolveValue(null, e.stateId, e.stateName),
      toZipCode: e.zipCode,
    });
  }

  function changeJobField(e) {
    const jobEdit = Object.assign({}, job);
    jobEdit[e.target.name] = e.target.value;
    setJob(jobEdit);
  }

  function changeJobBoolean(checked, field) {
    const jobEdit = Object.assign({}, job);
    jobEdit[field] = checked;
    setJob(jobEdit);
  }

  function changeJobSelect(selection, label) {
    const jobEdit = Object.assign({}, job);
    jobEdit[label] = selection;
    setJob(jobEdit);
  }

  function onAddJobChecklistItem(selection) {
    let newList = Object.assign([], jobChecklistItems);
    let itemToSave = {
      id: 0, // will be added as a new jobChecklistItem
      checkListItem: {
        id: selection.value,
        name: selection.label, // jobChecklistItem reference to org's checklist item
        prompt: selection.prompt,
        checkListItemTypeName: selection.checkListItemTypeName,
        isRequired: selection.isRequired,
      },
      local_id: -1 * (jobChecklistItems.length + 1),
    };
    newList = [...newList, itemToSave];
    setJobChecklistItems(newList);
  }

  function onRemoveJobChecklistItem(localItemId) {
    const indexToEdit = _.findIndex(jobChecklistItems, {
      local_id: localItemId,
    });
    const newList = Object.assign([], jobChecklistItems);
    newList.splice(indexToEdit, 1);
    setJobChecklistItems(newList);
  }

  function onJobRigChange(selection) {
    // need to alter if allowing more than one per job
    let tempJobRigs = Object.assign([], jobRigs);
    tempJobRigs = _.each(tempJobRigs, (jr) => tempJobRigs.pop(jr));
    tempJobRigs.push(selection);
    setJobRigs(tempJobRigs);
  }

  function convertDocToFormData(doc, jobId) {
    const formData = new FormData();
    formData.append("file", doc.file);
    formData.append("orgId", doc.orgId);
    formData.append("jobId", jobId);
    formData.append("id", doc.id);
    formData.append("documentTypeId", doc.documentTypeId);
    return formData;
  }

  const uploadAllDocuments = (documents, jobId) => {
    return new Promise(async (resolve, reject) => {
      let allUploaded = true;
      for (let doc of documents) {
        // each document upload will happen one at a time. Might need to change this to allow to run parallelly in future
        let formData = convertDocToFormData(doc, jobId);
        await api
          .post_form_data("Job/SaveJobDocument", formData)
          .then((response) => {
            if (response.data && response.data.success) {
              // on success ?
            } else {
              allUploaded = false;
            }
          })
          .catch(() => (allUploaded = false));
      }
      if (allUploaded) resolve("All uploaded");
      else reject("All documents could not be uploaded");
    });
  };

  async function onChangeUnit(selection) {
    let jobEdit = Object.assign({}, job);
    await api
      .fetch(`Unit/GetUnit/${selection.value}`)
      .then((response) => {
        let tempUnit = Object.assign({}, response.data);
        jobEdit = {
          ...jobEdit,
          unit: helpers.resolveValue(null, tempUnit.id, tempUnit.serialNumber),
          serialNumber: helpers.nullableString(tempUnit.serialNumber),
          unitType: helpers.resolveValue(
            null,
            tempUnit.unitTypeId,
            tempUnit.unitTypeName
          ),
          unitMaterial: helpers.resolveValue(
            null,
            tempUnit.unitMaterialId,
            tempUnit.unitMaterialName
          ),
          unitStyle: helpers.resolveValue(
            null,
            tempUnit.unitStyleId,
            tempUnit.unitStyleName
          ),
          wallColor: helpers.resolveValue(
            null,
            tempUnit.wallColorId,
            tempUnit.wallColorName
          ),
          trimColor: helpers.resolveValue(
            null,
            tempUnit.trimColorId,
            tempUnit.trimColorName
          ),
          roofColor: helpers.resolveValue(
            null,
            tempUnit.roofColorId,
            tempUnit.roofColorName
          ),
          manufacturer: helpers.nullableString(tempUnit.manufacturer),
          heightInFeet: tempUnit.heightInFeet,
          widthInFeet: tempUnit.widthInFeet,
          lengthInFeet: tempUnit.lengthInFeet,
          comment: helpers.nullableString(tempUnit.comment),
        };
        setJob(jobEdit);
      })
      .catch(helpers.catchHandler);
  }

  function onDateChange(selected, field) {
    const editJob = Object.assign({}, job);
    editJob[field] = selected;
    setJob(editJob);
  }

  function onTimeChange(event, field) {
    const editJob = Object.assign({}, job);
    editJob[field] = event.target.value;
    setJob(editJob);
  }

  function onSaveJob() {
    window.scroll(0, 0);
    let warnings = [];
    if (!job.jobStatus) {
      warnings.push("Valid job status is required."); // in case of bad data
    }
    if (!job.jobType) {
      warnings.push("Valid job type is required."); // in case of bad data
    }
    if (!fromAddress.fromAddress1) {
      warnings.push("Valid origin address is required.");
    }
    if (!fromAddress.fromCity) {
      warnings.push("Valid origin city is required.");
    }
    if (!fromAddress.fromState) {
      warnings.push("Valid origin state is required.");
    }
    if (!fromAddress.fromZipCode) {
      warnings.push("Valid origin zip code is required.");
    }
    if (!toAddress.toAddress1) {
      warnings.push("Valid destination address is required.");
    }
    if (!toAddress.toCity) {
      warnings.push("Valid destination city is required.");
    }
    if (!toAddress.toState) {
      warnings.push("Valid destination state is required.");
    }
    if (!toAddress.toZipCode) {
      warnings.push("Valid destination zip code is required.");
    }
    if (!job.manufacturer || _.trim(job.manufacturer).length < 2) {
      warnings.push("Please provide a valid manufacturer.");
    }
    if (!job.lengthInFeet) {
      warnings.push("Building length in feet is required.");
    }
    if (!job.widthInFeet) {
      warnings.push("Building width in feet is required.");
    }
    if (!job.heightInFeet) {
      warnings.push("Building height in feet is required.");
    }
    if (warnings.length) {
      setMessage({
        text: warnings.join(" "),
        flavor: "alert-danger",
      });
      return;
    } else {
      setMessage(null);
    }
    let jobPayload = {
      id: job.id,
      jobTypeId: job.jobType.value,
      typeOfJobStatus: job.jobStatus.value,
      orgId: job.orgId ? job.orgId : userOrgId, // if updating job, keep same orgId (cannot be changed)
      requestedByDate: job.requestedByDate,
      parentJobId: job.parentJobId,
      referenceNumber: job.referenceNumber,
      createdByOrgPrimaryContactFirstName:
        job.createdByOrgPrimaryContactFirstName,
      createdByOrgPrimaryContactLastName:
        job.createdByOrgPrimaryContactLastName,
      createdByOrgPrimaryContactNumber: job.createdByOrgPrimaryContactNumber,
      createdByOrgEmail: job.createdByOrgEmail,
      fromStreetAddress1: fromAddress.fromAddress1,
      fromStreetAddress2: fromAddress.fromAddress2,
      fromCity: fromAddress.fromCity,
      fromStateId: fromAddress.fromState ? fromAddress.fromState.value : null,
      fromZipCode: fromAddress.fromZipCode,
      pickupLat: fromAddress.pickupLat ? fromAddress.pickupLat : null,
      pickupLong: fromAddress.pickupLong ? fromAddress.pickupLong : null,
      toAddress1: toAddress.toAddress1,
      toAddress2: toAddress.toAddress2,
      toCity: toAddress.toCity,
      toStateId: toAddress.toState ? toAddress.toState.value : null,
      toZipCode: toAddress.toZipCode,
      dropoffLat: toAddress.dropoffLat ? toAddress.dropoffLat : null,
      dropoffLong: toAddress.dropoffLong ? toAddress.dropoffLong : null,
      compensationAmount: Number(totalFees) * 100,
      customerFeeCollectedAt: job.customerFeeCollectedAt,
      unitId: job.unit ? job.unit.value : null,
      unitTypeId: job.unitType.value,
      unitStyleId:
        job.unitStyle && job.unitStyle.value ? job.unitStyle.value : null, // FIX: UnitStyleId comes back as 0 from UnitMap if UnitStyle is null
      unitMaterialId: job.unitMaterial.value,
      wallColorId: job.wallColor.value,
      trimColorId: job.trimColor.value,
      roofColorId: job.roofColor.value,
      otherWallColor:
        job.wallColor.value == constants.COLOR_IDS.OTHER
          ? job.otherWallColor
          : null,
      otherTrimColor:
        job.trimColor.value == constants.COLOR_IDS.OTHER
          ? job.otherTrimColor
          : null,
      otherRoofColor:
        job.roofColor.value == constants.COLOR_IDS.OTHER
          ? job.otherRoofColor
          : null,
      manufacturer: job.manufacturer,
      serialNumber: job.serialNumber,
      heightInFeet: job.heightInFeet,
      widthInFeet: job.widthInFeet,
      lengthInFeet: job.lengthInFeet,
      unitComment: job.unitComment,
      ownerFirstName: job.ownerFirstName,
      ownerLastName: job.ownerLastName,
      ownerEmail: job.ownerEmail,
      ownerPhoneNumber: job.ownerPhoneNumber,
      reservedByOrgId:
        sendHaulerInvite && job.reservedByOrg ? job.reservedByOrg.value : null,
      driverId:
        sendHaulerInvite && job.driver && job.driver.value
          ? job.driver.value
          : null,
      sendHaulerInvite: sendHaulerInvite,
      isExtreme: job.isExtreme,
    };
    const jobFeesPayload = _.map(jobFees, (f) => ({
      id: f.id ? f.id : 0,
      feeTypeId: f.feeType.value,
      amount: f.amount,
      description: f.description,
      includeFee: true,
    }));
    const jobCommentsPayload = _.map(comments, (c) => ({
      id: c.id ? c.id : 0,
      comment: c.comment,
      privateToOrgId: c.isPrivate ? userOrgId : null,
    }));
    const jobChecklistItemsPayload = _.map(jobChecklistItems, (item) => ({
      Id: item.id ? item.id : 0,
      CheckListItemId: item.checkListItem.id,
      Required: item.checkListItem.isRequired,
    }));
    let payload = {
      job: jobPayload,
      jobFees: jobFeesPayload,
      jobComments: jobCommentsPayload,
      jobChecklistItems: jobChecklistItemsPayload,
    };
    api
      .post("Job/SaveJob", payload)
      .then((response) => {
        if (response.data && response.data.success) {
          uploadAllDocuments(newDocumentsToUpload, response.data.message.id)
            .then((response) => {
              if (isJobOwner) {
                setMessage({
                  text: "Job has been updated successfully! You will be redirected momentarily.",
                  flavor: "alert-success",
                });
                setTimeout(() => {
                  setRedirect(true);
                }, 2000);
              } else {
                setMessage({
                  text: "Comments saved successfully.",
                  flavor: "alert-success",
                });
              }
            })
            .catch((error) => {
              setMessage({ text: error, flavor: "alert-danger" });
            });
        } else {
          setMessage({ text: response.data.message, flavor: "alert-danger" });
        }
      })
      .catch(helpers.catchHandler);
  }

  function onAddNewFee() {
    setNewFee(Object.assign({}, emptyJobFee));
  }

  function onCancelAddNewJobFee() {
    setNewFee(null);
  }

  function onNewJobFeeTypeSelect(selection, name) {
    setNewFee({
      ...newFee,
      [name]: selection,
    });
  }

  function changeNewJobFeeField(event) {
    setNewFee({
      ...newFee,
      [event.target.name]: event.target.value,
    });
  }

  const newJobFeeRow = () => {
    return (
      <React.Fragment>
        <td width="20%">
          <Select
            closeMenuOnSelect={true}
            value={newFee.feeType}
            name="feeType"
            options={constants.JOB_FEES} // FIX: make api call to get these instead
            onChange={(event) => onNewJobFeeTypeSelect(event, "feeType")}
            isDisabled={jobReserved}
          />
        </td>
        <td width="30%">
          <Input
            type="text"
            name="description"
            id="description"
            onChange={(event) => changeNewJobFeeField(event)}
            value={newFee.dsc}
            className="form-control"
            disabled={jobReserved}
            maxLength="500"
          />
        </td>
        <td>
          <Input
            type="number"
            name="amount"
            id="amount"
            onChange={(event) => changeNewJobFeeField(event)}
            // value={item.charge || (item.type && item.type.defaultPrice) || 0}
            value={newFee.amount}
            className="form-control"
          />
        </td>
        <td>
          <ButtonGroup className="float-right">
            <Button
              size="sm"
              className="projectSuccess"
              onClick={() => onSaveNewJobFee()}
              // onClick={() => addToJobFees(item)}
              title="Add to quote"
              disabled={
                !(
                  newFee &&
                  newFee.feeType &&
                  newFee.description &&
                  newFee.amount
                )
              }
            >
              <FontAwesomeIcon icon="save" />
            </Button>
            <Button
              size="sm"
              className="projectSecondary"
              onClick={() => onCancelAddNewJobFee()}
              title="Cancel New Job Fee"
            >
              <FontAwesomeIcon icon="reply" />
            </Button>
          </ButtonGroup>
        </td>
      </React.Fragment>
    );
  };

  function onEditJobFee(localJobFeeId) {
    const indexToEdit = _.findIndex(jobFees, { local_id: localJobFeeId });
    let tempList = _.cloneDeep(jobFees);
    const snapshotItem = Object.assign({}, tempList[indexToEdit]);
    tempList[indexToEdit].isEditing = true;
    tempList[indexToEdit].pristine = snapshotItem;
    setJobFees(tempList);
  }

  function onCancelEditJobFee(localJobFeeId) {
    const indexToEdit = _.findIndex(jobFees, { local_id: localJobFeeId });
    let tempList = _.cloneDeep(jobFees);
    tempList[indexToEdit].isEditing = false;
    tempList[indexToEdit] = tempList[indexToEdit].pristine;
    setJobFees(tempList);
  }

  function onEditJobFeeTypeSelect(selection, name, localJobFeeId) {
    const indexToEdit = _.findIndex(jobFees, { local_id: localJobFeeId });
    const newList = Object.assign([], jobFees);
    newList[indexToEdit][name] = selection;
    setJobFees(newList);
  }

  function changeJobFee(event, localJobFeeId) {
    const indexToEdit = _.findIndex(jobFees, { local_id: localJobFeeId });
    const newList = Object.assign([], jobFees);
    newList[indexToEdit][event.target.name] = event.target.value;
    // if(event.target.name === 'amount') {
    //   newList[indexToEdit].amountDisplay = `$${event.target.value}`;    // FIX
    // }
    setJobFees(newList);
  }

  function onSaveEditedJobFee(localJobFeeId) {
    const indexToEdit = _.findIndex(jobFees, { local_id: localJobFeeId });
    const newList = Object.assign([], jobFees);
    newList[indexToEdit].isEditing = false;
    setJobFees(newList);
    let fees = Number(totalFees) - Number(newList[indexToEdit].pristine.amount);
    fees = Number(fees) + Number(newList[indexToEdit].amount);
    setTotalFees(fees);
  }

  function onSaveNewJobFee() {
    let newList = Object.assign([], jobFees);
    let jobFeeToSave = Object.assign({}, newFee);
    // if (!this.isJobFeeValid()) return;
    jobFeeToSave = {
      ...jobFeeToSave,
      local_id: -1 * (jobFees.length + 1),
    };
    newList = [...newList, jobFeeToSave];
    setJobFees(newList);
    setNewFee(null);
    addToJobFees(jobFeeToSave.amount);
    // const fees = totalFees + parseInt(jobFeeToSave.amount, 10);
    // setTotalFees(fees);
  }

  function addToJobFees(amount) {
    const fees = Number(totalFees) + Number(amount);
    setTotalFees(fees);
  }

  function deleteFromJobFees(amount) {
    const fees = Number(totalFees) - Number(amount);
    setTotalFees(fees);
  }

  function onDeleteJobFee(localJobFeeId, amount) {
    const indexToEdit = _.findIndex(jobFees, { local_id: localJobFeeId });
    const newList = Object.assign([], jobFees); // const newList = _.cloneDeep(jobFees);
    newList.splice(indexToEdit, 1);
    setJobFees(newList);
    deleteFromJobFees(amount);
    // const fees = totalFees - parseInt(amount, 10);
    // setTotalFees(fees);
  }

  const jobFeeRow = (item) => {
    return (
      <React.Fragment>
        <td width="20%">
          <Select
            closeMenuOnSelect={true}
            value={item.feeType || null}
            name="feeType"
            options={constants.JOB_FEES} // FIX: make api call to get these instead?
            onChange={(event) =>
              onEditJobFeeTypeSelect(event, "feeType", item.local_id)
            }
            isDisabled={jobReserved}
          />
        </td>
        <td width="30%">
          <Input
            type="text"
            name="description"
            id="description"
            onChange={(event) => changeJobFee(event, item.local_id)}
            value={item.description}
            className="form-control"
            disabled={jobReserved}
            maxLength="500"
          />
        </td>
        <td>
          <Input
            type="number"
            name="amount"
            id="amount"
            onChange={(event) => changeJobFee(event, item.local_id)}
            value={item.amount}
            className="form-control"
          />
        </td>
        <td>
          <ButtonGroup className="float-right">
            <Button
              size="sm"
              className="projectSuccess"
              // onClick={() => addToJobFees(item)}
              onClick={() => onSaveEditedJobFee(item.local_id)}
              title="Add to quote"
              disabled={
                !(item && item.feeType && item.description && item.amount)
              }
            >
              <FontAwesomeIcon icon="save" />
            </Button>
            <Button
              size="sm"
              className="projectSecondary"
              onClick={() => onCancelEditJobFee(item.local_id)}
              title="Cancel Edit Job Fee"
            >
              <FontAwesomeIcon icon="reply" />
            </Button>
          </ButtonGroup>
        </td>
      </React.Fragment>
    );
  };

  function onAddNewComment() {
    setNewComment(Object.assign({}, emptyComment));
  }

  function onCancelAddNewComment() {
    setNewComment(null);
  }

  function onNewCommentIsPrivateChange(checked, field) {
    const commentEdit = Object.assign({}, newComment);
    commentEdit[field] = checked;
    setNewComment(commentEdit);
  }

  function changeNewCommentField(event) {
    setNewComment({
      ...newComment,
      [event.target.name]: event.target.value,
    });
  }

  const newCommentRow = () => {
    return (
      <React.Fragment>
        <td width="40%">
          <Input
            type="text"
            name="comment"
            id="commment"
            onChange={(event) => changeNewCommentField(event)}
            value={newComment.dsc}
            className="form-control"
          />
        </td>
        <td width="30%">
          <Switch
            name="isPrivate"
            onChange={(e) => onNewCommentIsPrivateChange(e, "isPrivate")}
            checked={newComment && newComment.isPrivate === true ? true : false}
          />
        </td>
        <td>
          <ButtonGroup className="float-right">
            <Button
              size="sm"
              className="projectSuccess"
              onClick={() => onSaveNewComment()}
              title="Add comment"
              disabled={!(newComment && newComment.comment)}
            >
              <FontAwesomeIcon icon="save" />
            </Button>
            <Button
              size="sm"
              className="projectSecondary"
              onClick={() => onCancelAddNewComment()}
              title="Cancel New Comment"
            >
              <FontAwesomeIcon icon="reply" />
            </Button>
          </ButtonGroup>
        </td>
      </React.Fragment>
    );
  };

  function onEditComment(localCommentId) {
    const indexToEdit = _.findIndex(comments, { local_id: localCommentId });
    let tempList = _.cloneDeep(comments);
    const snapshotItem = Object.assign({}, tempList[indexToEdit]);
    tempList[indexToEdit].isEditing = true;
    tempList[indexToEdit].pristine = snapshotItem;
    setComments(tempList);
  }

  function onCancelEditComment(localCommentId) {
    const indexToEdit = _.findIndex(comments, { local_id: localCommentId });
    let tempList = _.cloneDeep(comments);
    tempList[indexToEdit].isEditing = false;
    tempList[indexToEdit] = tempList[indexToEdit].pristine;
    setComments(tempList);
  }

  function changeCommentField(event, localCommentId) {
    const indexToEdit = _.findIndex(comments, { local_id: localCommentId });
    const newList = Object.assign([], comments);
    newList[indexToEdit][event.target.name] = event.target.value;
    setComments(newList);
  }

  function onCommentIsPrivateChange(checked, field, localCommentId) {
    const indexToEdit = _.findIndex(comments, { local_id: localCommentId });
    const newList = Object.assign([], comments);
    newList[indexToEdit][field] = checked;
    setComments(newList);
  }

  function onSaveEditedComment(localCommentId) {
    const indexToEdit = _.findIndex(comments, { local_id: localCommentId });
    const newList = Object.assign([], comments);
    newList[indexToEdit].isEditing = false;
    setComments(newList);
  }

  function onSaveNewComment() {
    let newList = Object.assign([], comments);
    let commentToSave = Object.assign({}, newComment);
    // if (!this.isCommentValid()) return;
    commentToSave = {
      ...commentToSave,
      local_id: -1 * (comments.length + 1),
    };
    newList = [...newList, commentToSave];
    setComments(newList);
    setNewComment(null);
  }

  function onDeleteComment(localCommentId) {
    const indexToEdit = _.findIndex(comments, { local_id: localCommentId });
    const newList = Object.assign([], comments); // const newList = _.cloneDeep(jobFees);
    newList.splice(indexToEdit, 1);
    setComments(newList);
  }

  const jobCommentRow = (item) => {
    return (
      <React.Fragment>
        <td width="40%">
          <Input
            type="text"
            name="comment"
            id="commment"
            onChange={(event) => changeCommentField(event, item.local_id)}
            value={item.comment}
            className="form-control"
            maxLength="500"
          />
        </td>
        <td width="30%">
          <Switch
            name="isPrivate"
            onChange={(e) =>
              onCommentIsPrivateChange(e, "isPrivate", item.local_id)
            }
            checked={item && item.isPrivate === true ? true : false}
          />
        </td>
        <td>
          <ButtonGroup className="float-right">
            <Button
              size="sm"
              className="projectSuccess"
              onClick={() => onSaveEditedComment(item.local_id)}
              title="Edit comment"
              disabled={!(item && item.comment)}
            >
              <FontAwesomeIcon icon="save" />
            </Button>
            <Button
              size="sm"
              className="projectSecondary"
              onClick={() => onCancelEditComment(item.local_id)}
              title="Cancel Edit Comment"
            >
              <FontAwesomeIcon icon="reply" />
            </Button>
          </ButtonGroup>
        </td>
      </React.Fragment>
    );
  };

  function onScheduleJob() {
    window.scroll(0, 0);
    let jobCopy = Object.assign({}, job);
    if (!jobCopy.driver) {
      Alert.error("Please assign a driver.");
      return;
    }
    if (!jobCopy.estimatedPickupAt) {
      Alert.error("Pickup Date is required.");
      return;
    }
    if (!jobCopy.estimatedDropoffAt) {
      Alert.error("Dropoff Date is required.");
      return;
    }
    let payload = {
      JobId: jobCopy ? jobCopy.id : null,
      DriverId: jobCopy.driver ? jobCopy.driver.value : null,
      AssignedToOrgId: currentUser.currentOrgId,
      EstimatedPickupAt: dateHelpers.formatDateForServer(
        jobCopy.estimatedPickupAt
      ), //jobCopy.estimatedPickupAt,
      EstimatedDropoffAt: dateHelpers.formatDateForServer(
        jobCopy.estimatedDropoffAt
      ), //jobCopy.estimatedDropoffAt,
      EstimatedPickupTimeOfDay: jobCopy.estimatedPickupTimeOfDay,
      EstimatedDropoffTimeOfDay: jobCopy.estimatedDropoffTimeOfDay,
    };

    api
      .post("Job/ScheduleJob", payload)
      .then((response) => {
        if (response.data && response.data.success) {
          // Alert.success("Job has been scheduled.");
          setMessage({
            text: "Job has been scheduled! You will be redirected momentarily.",
            flavor: "alert-success",
          });
          setTimeout(() => {
            setRedirect(true);
          }, 2000);
        } else {
          // Alert.error("An error occurred. Job cannot be scheduled at this time.");
          setMessage({ text: response.data.message, flavor: "alert-danger" });
        }
      })
      .catch(helpers.catchHandler);
  }

  function onCompleteJob() {
    window.scroll(0, 0);
    let jobCopy = Object.assign({}, job);
    if (!jobCopy.actualPickupAt && !jobCopy.actualPickupAtTime) {
      Alert.error("Actual Pickup At date and time is required.");
      return;
    }
    if (!jobCopy.actualDropoffAt && !jobCopy.actualDropoffAtTime) {
      Alert.error("Actual Dropoff At date and time is required.");
      return;
    }

    let tempRequiredItems = _.filter(
      jobChecklistItems,
      (x) => x.required == true
    );
    let requiredChecklistItems = _.map(tempRequiredItems, (x) => x.id);
    let itemsAnswered = _.map(checklistAnswers, (x) => x.jobChecklistItemId);

    let isValid = _.every(requiredChecklistItems, (ri) =>
      _.includes(itemsAnswered, ri)
    );

    if (!isValid) {
      Alert.error("A required checklist item has not been answered");
      return;
    }

    if (!window.confirm(`Are you sure you'd like to Complete this job?`))
      return;

    const jobRigsPayload = _.map(jobRigs, (jr) => ({
      Id: jr.id ? jr.id : 0,
      RigTypeId: jr.value,
    }));

    let payload = {
      JobId: jobCopy ? jobCopy.id : null,
      // Need to send : '2021-08-04T04:00:00.000Z'
      // actualPickupAt: Tue Aug 17 2021 10:12:11 GMT-0400 (Eastern Daylight Time)
      // actualPickupAtTime: '12:00'
      actualPickupAt: job.actualPickupAt, //`${job.actualPickupAt}T${job.actualPickupAtTime}`
      actualDropoffAt: job.actualDropoffAt, //`${job.actualDropoffAt}T${job.actualDropoffAtTime}`
      jobRigs: jobRigsPayload,
    };

    Promise.all(
      _.map(checklistAnswers, async (v) => {
        setMessage("Saving Responses");
        let newResponse = {};
        newResponse.JobChecklistItemId = parseInt(v.jobChecklistItemId, 10);
        newResponse.ChecklistItemTypeId = parseInt(v.checklistItemTypeId, 10);
        newResponse.DocumentTypeId = v.documentTypeId
          ? parseInt(v.documentTypeId, 10)
          : null;
        newResponse.Sequence = parseInt(v.sequence, 10);
        newResponse.Prompt = v.prompt;
        switch (v.checklistItemTypeId) {
          case constants.CHECKLIST_ITEM_TYPE_IDS.BOOLEAN:
            newResponse.BoolResponse = v.value;
            break;
          case constants.CHECKLIST_ITEM_TYPE_IDS.ATTACHMENT:
          case constants.CHECKLIST_ITEM_TYPE_IDS.SIGNATURE:
            const formData = new FormData();
            formData.append("OrgId", currentUser.currentOrgId);
            formData.append("JobId", jobId);
            formData.append("Id", currentUser.id);
            formData.append("DocumentTypeId", v.documentTypeId);
            if (v.value) {
              formData.append(`File`, v.value);
            }
            let response = await api.post_form_data(
              `Job/SaveJobDocument`,
              formData
            );

            if (response.data.success && response.data.message) {
              newResponse.AttachmentResponse = response.data.message;
            } else {
              setMessage({
                text: response.data.message,
                flavor: "alert-danger",
              });
              throw new Error(response.data.message);
            }
            break;
          case constants.CHECKLIST_ITEM_TYPE_IDS.TEXT:
            newResponse.TextResponse = v.value;
            break;
          case constants.CHECKLIST_ITEM_TYPE_IDS.PAYMENT:
            newResponse.PaymentResponse = v.value;
            break;
          default:
            const message =
              "Unknown checklistItemTypes: " + v.checkListItemTypeId;
            console.warn(message);
            throw new Error(message);
        }
        return newResponse;
      })
    )
      .then((r) => {
        if (r) {
          Alert.success("Marking Job Complete");
          payload.CheckListItemResponses = r;
          return api.post(`Job/CompleteJob`, payload);
        } else {
          setMessage({
            text: "Error setting checklist responses.",
            flavor: "alert-danger",
          });
          throw new Error("Error setting checklist responses.");
        }
      })
      .then((response) => {
        if (response.data.success) {
          setMessage({
            text: "Job has been completed successfully! You will be redirected momentarily.",
            flavor: "alert-success",
          });
          setTimeout(() => {
            setRedirect(true);
          }, 2000);
        } else {
          setMessage({ text: response.data.message, flavor: "alert-danger" });
        }
      })
      .catch(helpers.catchHandler)
      .finally(() => {
        setMessage(null);
      });
  }

  if (redirect) {
    return <Redirect to={`/jobs`} />;
  }

  if (!loading) {
    console.log("Is Job Owner", isJobOwner);
    console.log("Current Org", currentUser.currentOrgId);
    console.log("Reserved By Org", job?.reservedByOrg?.value);
    return (
      <Media>
        {({ breakpoints, currentBreakpoint }) => {
          switch (currentBreakpoint) {
            case breakpoints.mobile:
              return <div>{props.children}</div>;
            case breakpoints.desktop:
            default:
              return (
                <DesktopContainer
                  screenName={
                    jobId > 0 ? (
                      <Fragment>
                        <span>{`Job #${jobId}`} </span>
                        <i className="fontSize85 float-right">
                          <i className="fontSize85">
                            {job.jobStatus ? job.jobStatus.label : null}
                          </i>
                        </i>
                      </Fragment>
                    ) : (
                      `Request New Hauling Job`
                    )
                  }
                >
                  {message ? (
                    <Alert2 className={message.flavor}>{message.text}</Alert2>
                  ) : null}
                  {showTextModal && (
                    <TextNotificationModal
                      data={showTextModal}
                      onClose={() => setShowTextModal(null)}
                    />
                  )}
                  <Row className="mb-2">
                    <Col xs="12" className="mx-0 px-0">
                      <CollapsibleCardSection
                        cardName={
                          !isJobOwner ? "Job Details" : "Required Fields"
                        }
                        cardBody={
                          <Fragment>
                            <Row className="mb-4 mt-2">
                              {!isJobOwner && (
                                <Col xs="3">
                                  Company
                                  <div>
                                    <Input
                                      type="text"
                                      value={job.orgName}
                                      className="form-control"
                                      disabled={true}
                                    />
                                  </div>
                                </Col>
                              )}
                              <Col xs="3">
                                Job Type {helpers.requiredStar()}
                                <Select
                                  name="jobType"
                                  id="jobType"
                                  closeMenuOnSelect={true}
                                  value={job.jobType}
                                  options={jobTypeList}
                                  onChange={(event) => onChangeJobType(event)}
                                  isDisabled={!draftMode}
                                />
                              </Col>
                              <Col xs="3">
                                PO or Reference # (optional)
                                <Input
                                  type="text"
                                  name="referenceNumber"
                                  id="referenceNumber"
                                  onChange={(event) => changeJobField(event)}
                                  value={job.referenceNumber}
                                  className="form-control"
                                  disabled={isHaulerUser}
                                  maxLength="100"
                                />
                              </Col>
                              <Col xs="2">
                                Requested By Date
                                <div>
                                  <DatePicker
                                    name="requestedByDate"
                                    selected={job.requestedByDate}
                                    className="form-control"
                                    placeholderText="Select date"
                                    onChange={(e) =>
                                      onDateChange(e, "requestedByDate")
                                    }
                                    disabled={isHaulerUser}
                                  />
                                </div>
                              </Col>
                              <Col xs="1">
                                Extreme
                                <div>
                                  <Switch
                                    name="isExtreme"
                                    onChange={(e) =>
                                      changeJobBoolean(e, "isExtreme")
                                    }
                                    checked={
                                      job && job.isExtreme === true
                                        ? true
                                        : false
                                    }
                                    disabled={isHaulerUser}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className="mb-2 mt-4">
                              <Col xs="6">
                                <h5>Contact Information</h5>
                              </Col>
                            </Row>
                            <Row className="mb-5 mt-2">
                              <Col xs="3">
                                Primary Contact First Name
                                <Input
                                  type="text"
                                  name="createdByOrgPrimaryContactFirstName"
                                  id="createdByOrgPrimaryContactFirstName"
                                  onChange={(event) => changeJobField(event)}
                                  value={
                                    job.createdByOrgPrimaryContactFirstName ||
                                    (orgPrimaryUser
                                      ? orgPrimaryUser.firstName
                                      : "")
                                  }
                                  className="form-control"
                                  disabled={isHaulerUser || jobComplete}
                                  maxLength="50"
                                />
                              </Col>
                              <Col xs="3">
                                Primary Contact Last Name
                                <Input
                                  type="text"
                                  name="createdByOrgPrimaryContactLastName"
                                  id="createdByOrgPrimaryContactLastName"
                                  onChange={(event) => changeJobField(event)}
                                  value={
                                    job.createdByOrgPrimaryContactLastName ||
                                    (orgPrimaryUser
                                      ? orgPrimaryUser.lastName
                                      : "")
                                  }
                                  className="form-control"
                                  disabled={isHaulerUser || jobComplete}
                                  maxLength="50"
                                />
                              </Col>
                              <Col xs="3">
                                Primary Contact Phone Number
                                <Input
                                  type="text"
                                  name="createdByOrgPrimaryContactNumber"
                                  id="createdByOrgPrimaryContactNumber"
                                  onChange={(event) => changeJobField(event)}
                                  value={
                                    job.createdByOrgPrimaryContactNumber ||
                                    (orgPrimaryUser
                                      ? orgPrimaryUser.phoneNumber
                                      : "")
                                  }
                                  className="form-control"
                                  disabled={isHaulerUser || jobComplete}
                                  maxLength="20"
                                />
                              </Col>
                              <Col xs="3">
                                Primary Contact Email
                                <Input
                                  type="text"
                                  name="createdByOrgEmail"
                                  id="createdByOrgEmail"
                                  onChange={(event) => changeJobField(event)}
                                  value={
                                    job.createdByOrgEmail ||
                                    (orgPrimaryUser ? orgPrimaryUser.email : "")
                                  }
                                  className="form-control"
                                  disabled={isHaulerUser || jobComplete}
                                  maxLength="320"
                                />
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <Col xs="6">
                                <Row className="mb-2">
                                  <Col xs="5">
                                    <h5>
                                      Origin Address {helpers.requiredStar()}
                                    </h5>
                                  </Col>
                                  {/* Unable to get lat & lng for pickup location with this, need to update */}
                                  {/* {draftMode &&
                                  (job.jobType.value ===
                                    constants.JOB_TYPE_IDS.MFG ||
                                    job.jobType.value ===
                                      constants.JOB_TYPE_IDS.RTO_DELIVERY) ? (
                                    <Col>
                                      <Select
                                        name="selectedOrgFromAddress"
                                        id="selectedOrgFromAddress"
                                        closeMenuOnSelect={true}
                                        options={orgLocations}
                                        onChange={(event) =>
                                          onChangeSelectedOrgFromAddress(event)
                                        }
                                        isDisabled={
                                          (!isJobOwner && !draftMode) ||
                                          jobComplete ||
                                          (orgLocations && !orgLocations.length)
                                        }
                                      />
                                    </Col>
                                  ) : null} */}
                                </Row>
                                <Row>
                                  <Col>
                                    <AddressAutocomplete
                                      id="fromAddressAutocomplete"
                                      value={typeaheadFromAddress}
                                      onChange={(e) =>
                                        setTypeaheadFromAddress(e.target.value)
                                      }
                                      disabled={
                                        (!isJobOwner && !draftMode) ||
                                        jobComplete
                                      }
                                      getAddressInfo={({
                                        city,
                                        streetNumber,
                                        route,
                                        zip,
                                        lat,
                                        lng,
                                        stateAbbr,
                                      }) => {
                                        let tempState = null;

                                        if (stateAbbr) {
                                          tempState = _.find(
                                            stateList,
                                            (s) => s.label === stateAbbr
                                          );
                                        }

                                        setFromAddress({
                                          ...fromAddress,
                                          fromAddress1: `${streetNumber} ${route}`,
                                          fromCity: city,
                                          fromZipCode: zip,
                                          pickupLat: lat,
                                          pickupLong: lng,
                                          fromState: tempState,
                                        });
                                        setTypeaheadFromAddress("");
                                      }}
                                    />
                                  </Col>
                                </Row>
                                <Fragment>
                                  <Row className="mb-1">
                                    <Col>
                                      <Input
                                        type="text"
                                        name="fromAddress1"
                                        onChange={(e) =>
                                          setFromAddress({
                                            ...fromAddress,
                                            fromAddress1: e.target.value,
                                          })
                                        }
                                        value={fromAddress.fromAddress1}
                                        className="form-control"
                                        placeholder="Address"
                                        disabled={
                                          (!isJobOwner && !draftMode) ||
                                          jobComplete
                                        }
                                        maxLength="255"
                                      />
                                    </Col>
                                  </Row>
                                  <Row className="mb-1">
                                    <Col>
                                      <Input
                                        type="text"
                                        name="fromStreetAddress2"
                                        onChange={(e) =>
                                          setFromAddress({
                                            ...fromAddress,
                                            fromAddress2: e.target.value,
                                          })
                                        }
                                        value={fromAddress.fromAddress2}
                                        className="form-control"
                                        placeholder="Address Line 2 (optional)"
                                        disabled={
                                          (!isJobOwner && !draftMode) ||
                                          jobComplete
                                        }
                                        maxLength="255"
                                      />
                                    </Col>
                                  </Row>
                                </Fragment>
                                <Row className="mb-3">
                                  <Col xs="6">
                                    <Input
                                      type="text"
                                      name="fromCity"
                                      onChange={(e) =>
                                        setFromAddress({
                                          ...fromAddress,
                                          fromCity: e.target.value,
                                        })
                                      }
                                      value={fromAddress.fromCity}
                                      className="form-control"
                                      placeholder="City"
                                      disabled={
                                        (!isJobOwner && !draftMode) ||
                                        jobComplete
                                      }
                                      maxLength="255"
                                    />
                                  </Col>
                                  <Col xs="2">
                                    <Select
                                      name="fromState"
                                      closeMenuOnSelect={true}
                                      value={fromAddress.fromState}
                                      options={stateList}
                                      onChange={(x) =>
                                        setFromAddress({
                                          ...fromAddress,
                                          fromState: x,
                                        })
                                      }
                                      isDisabled={
                                        (!isJobOwner && !draftMode) ||
                                        jobComplete
                                      }
                                    />
                                  </Col>
                                  <Col xs="4">
                                    <Input
                                      type="text"
                                      name="fromZipCode"
                                      onChange={(e) =>
                                        setFromAddress({
                                          ...fromAddress,
                                          fromZipCode: e.target.value,
                                        })
                                      }
                                      value={fromAddress.fromZipCode}
                                      className="form-control"
                                      placeholder="Zip"
                                      disabled={
                                        (!isJobOwner && !draftMode) ||
                                        jobComplete
                                      }
                                      maxLength="11"
                                    />
                                  </Col>
                                </Row>
                              </Col>
                              <Col xs="6">
                                <Row className="mb-2">
                                  <Col xs="5">
                                    <h5>
                                      Destination Address{" "}
                                      {helpers.requiredStar()}
                                    </h5>
                                  </Col>
                                  {/* Unable to get lat & lng for dropoff location with this, need to update */}
                                  {/* {job.jobType.value ===
                                    constants.JOB_TYPE_IDS.RETURN ||
                                  job.jobType.value ===
                                    constants.JOB_TYPE_IDS.RETRIEVAL ||
                                  job.jobType.value ===
                                    constants.JOB_TYPE_IDS.OFF_LOT ? (
                                    <Col>
                                      <Select
                                        name="selectedOrgToAddress"
                                        id="selectedOrgToAddress"
                                        closeMenuOnSelect={true}
                                        options={orgLocations}
                                        onChange={(event) =>
                                          onChangeSelectedOrgToAddress(event)
                                        }
                                        isDisabled={
                                          (!isJobOwner && !draftMode) ||
                                          jobComplete ||
                                          (orgLocations && !orgLocations.length)
                                        }
                                      />
                                    </Col>
                                  ) : null} */}
                                </Row>
                                <Row>
                                  <Col>
                                    <AddressAutocomplete
                                      id="toAddressAutocomplete"
                                      value={typeaheadToAddress}
                                      onChange={(e) =>
                                        setTypeaheadToAddress(e.target.value)
                                      }
                                      disabled={
                                        (!isJobOwner && !draftMode) ||
                                        jobComplete
                                      }
                                      getAddressInfo={({
                                        city,
                                        streetNumber,
                                        route,
                                        zip,
                                        lat,
                                        lng,
                                        stateAbbr,
                                      }) => {
                                        let tempState = null;

                                        if (stateAbbr) {
                                          tempState = _.find(
                                            stateList,
                                            (s) => s.label === stateAbbr
                                          );
                                        }

                                        setToAddress({
                                          ...toAddress,
                                          toAddress1: `${streetNumber} ${route}`,
                                          toCity: city,
                                          toZipCode: zip,
                                          dropoffLat: lat,
                                          dropoffLong: lng,
                                          toState: tempState || null,
                                        });
                                        setTypeaheadToAddress("");
                                      }}
                                    />
                                  </Col>
                                </Row>
                                <Fragment>
                                  <Row className="mb-1">
                                    <Col>
                                      <Input
                                        type="text"
                                        name="toAddress1"
                                        onChange={(e) =>
                                          setToAddress({
                                            ...toAddress,
                                            toAddress1: e.target.value,
                                          })
                                        }
                                        value={toAddress.toAddress1}
                                        className="form-control"
                                        placeholder="Address"
                                        disabled={
                                          (!isJobOwner && !draftMode) ||
                                          jobComplete
                                        }
                                        maxLength="255"
                                      />
                                    </Col>
                                  </Row>
                                  <Row className="mb-1">
                                    <Col>
                                      <Input
                                        type="text"
                                        name="toAddress2"
                                        onChange={(e) =>
                                          setToAddress({
                                            ...toAddress,
                                            toAddress2: e.target.value,
                                          })
                                        }
                                        value={toAddress.toAddress2}
                                        className="form-control"
                                        placeholder="Address Line 2 (optional)"
                                        disabled={
                                          (!isJobOwner && !draftMode) ||
                                          jobComplete
                                        }
                                        maxLength="255"
                                      />
                                    </Col>
                                  </Row>
                                </Fragment>
                                <Row className="mb-3">
                                  <Col xs="6">
                                    <Input
                                      type="text"
                                      name="toCity"
                                      onChange={(e) =>
                                        setToAddress({
                                          ...toAddress,
                                          toCity: e.target.value,
                                        })
                                      }
                                      value={toAddress.toCity}
                                      className="form-control"
                                      placeholder="City"
                                      disabled={
                                        (!isJobOwner && !draftMode) ||
                                        jobComplete
                                      }
                                      maxLength="255"
                                    />
                                  </Col>
                                  <Col xs="2">
                                    <Select
                                      name="toState"
                                      closeMenuOnSelect={true}
                                      value={toAddress.toState}
                                      options={stateList}
                                      onChange={(x) =>
                                        setToAddress({
                                          ...toAddress,
                                          toState: x,
                                        })
                                      }
                                      isDisabled={
                                        (!isJobOwner && !draftMode) ||
                                        jobComplete
                                      }
                                    />
                                  </Col>
                                  <Col xs="4">
                                    <Input
                                      type="text"
                                      name="toZipCode"
                                      onChange={(e) =>
                                        setToAddress({
                                          ...toAddress,
                                          toZipCode: e.target.value,
                                        })
                                      }
                                      value={toAddress.toZipCode}
                                      className="form-control"
                                      placeholder="Zip"
                                      disabled={
                                        (!isJobOwner && !draftMode) ||
                                        jobComplete
                                      }
                                      maxLength="11"
                                    />
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                            {!isJobOwner && jobOpen ? null : (
                              <Row className="mb-3">
                                <Col>
                                  <Row>
                                    <Col>
                                      <h5>Owner / Lessee Details</h5>
                                    </Col>
                                  </Row>
                                  <Row className="mb-3">
                                    <Col xs="3">
                                      Contact First Name
                                      <Input
                                        type="text"
                                        name="ownerFirstName"
                                        id="ownerFirstName"
                                        onChange={(event) =>
                                          changeJobField(event)
                                        }
                                        value={job.ownerFirstName}
                                        className="form-control"
                                        disabled={
                                          (!isJobOwner && !draftMode) ||
                                          jobComplete
                                        }
                                        maxLength="50"
                                      />
                                    </Col>
                                    <Col xs="3">
                                      Contact Last Name
                                      <Input
                                        type="text"
                                        name="ownerLastName"
                                        id="ownerLastName"
                                        onChange={(event) =>
                                          changeJobField(event)
                                        }
                                        value={job.ownerLastName}
                                        className="form-control"
                                        disabled={
                                          (!isJobOwner && !draftMode) ||
                                          jobComplete
                                        }
                                        maxLength="50"
                                      />
                                    </Col>
                                    <Col xs="3">
                                      Email
                                      <Input
                                        type="text"
                                        name="ownerEmail"
                                        id="ownerEmail"
                                        onChange={(event) =>
                                          changeJobField(event)
                                        }
                                        value={job.ownerEmail}
                                        className="form-control"
                                        disabled={
                                          (!isJobOwner && !draftMode) ||
                                          jobComplete
                                        }
                                        maxLength="75"
                                      />
                                    </Col>
                                    <Col xs="3">
                                      Phone Number
                                      <Input
                                        type="text"
                                        name="ownerPhoneNumber"
                                        id="ownerPhoneNumber"
                                        onChange={(event) =>
                                          changeJobField(event)
                                        }
                                        value={job.ownerPhoneNumber}
                                        className="form-control"
                                        disabled={
                                          (!isJobOwner && !draftMode) ||
                                          jobComplete
                                        }
                                        maxLength="20"
                                      />
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            )}
                            <Row className="mb-2 mt-4">
                              <Col xs="6">
                                <h5>Building Information</h5>
                              </Col>
                              {/* {!isJobOwner ? null : (
                                <Fragment>
                                  <Col xs="2">Select Existing Unit</Col>
                                  <Col xs="1">
                                    <Switch
                                      name="useExistingUnit"
                                      onChange={(e) =>
                                        changeJobBoolean(e, "useExistingUnit")
                                      }
                                      checked={
                                        job && job.useExistingUnit === true
                                          ? true
                                          : false
                                      }
                                      disabled={
                                        (!isJobOwner && !draftMode) ||
                                        jobComplete
                                      }
                                    />
                                  </Col>
                                </Fragment>
                              )} */}
                              {/* {isJobOwner && job.useExistingUnit ? (
                                <Col xs="3">
                                  <Select
                                    name="unit"
                                    id="unit"
                                    closeMenuOnSelect={true}
                                    options={unitList}
                                    onChange={(event) => onChangeUnit(event)}
                                    value={job.unit}
                                    classNamePrefix="react-select"
                                    isDisabled={
                                      (!isJobOwner && !draftMode) || jobComplete
                                    }
                                  />
                                </Col>
                              ) : null} */}
                            </Row>
                            <Row className="mb-2 align-items-end">
                              <Col xs="3">
                                Unit Type {helpers.requiredStar()}
                                <Select
                                  name="unitType"
                                  id="unitType"
                                  closeMenuOnSelect={true}
                                  value={job.unitType}
                                  options={unitTypeList}
                                  onChange={(event) =>
                                    changeJobSelect(event, "unitType")
                                  }
                                  isDisabled={
                                    (!isJobOwner && !draftMode) || jobComplete
                                  }
                                />
                              </Col>
                              <Col xs="3">
                                Manufacturer {helpers.requiredStar()}
                                <Input
                                  type="text"
                                  name="manufacturer"
                                  id="manufacturer"
                                  onChange={(event) => changeJobField(event)}
                                  value={job.manufacturer}
                                  className="form-control"
                                  disabled={
                                    (!isJobOwner && !draftMode) || jobComplete
                                  }
                                  maxLength="75"
                                />
                              </Col>
                              <Col xs="3">
                                Serial # (optional)
                                <Input
                                  type="text"
                                  name="serialNumber"
                                  id="serialNumber"
                                  onChange={(event) => changeJobField(event)}
                                  value={job.serialNumber}
                                  className="form-control"
                                  disabled={
                                    (!isJobOwner && !draftMode) || jobComplete
                                  }
                                  maxLength="50"
                                />
                              </Col>
                              <Col xs="3">
                                Style (optional)
                                <Select
                                  name="unitStyle"
                                  id="unitStyle"
                                  closeMenuOnSelect={true}
                                  value={job.unitStyle}
                                  options={unitStyleList}
                                  onChange={(event) =>
                                    changeJobSelect(event, "unitStyle")
                                  }
                                  isDisabled={
                                    (!isJobOwner && !draftMode) || jobComplete
                                  }
                                />
                              </Col>
                            </Row>
                            <Row className="mb-2 align-items-end">
                              <Col xs="3">
                                Material {helpers.requiredStar()}
                                <Select
                                  name="unitMaterial"
                                  id="unitMaterial"
                                  closeMenuOnSelect={true}
                                  value={job.unitMaterial}
                                  options={unitMaterialList}
                                  onChange={(event) =>
                                    changeJobSelect(event, "unitMaterial")
                                  }
                                  isDisabled={
                                    (!isJobOwner && !draftMode) || jobComplete
                                  }
                                />
                              </Col>
                              <Col xs="3">
                                Length (ft) {helpers.requiredStar()}
                                <Input
                                  type="number"
                                  name="lengthInFeet"
                                  id="lengthInFeet"
                                  onChange={(event) => changeJobField(event)}
                                  value={job.lengthInFeet}
                                  className="form-control"
                                  disabled={
                                    (!isJobOwner && !draftMode) || jobComplete
                                  }
                                />
                              </Col>
                              <Col xs="3">
                                Width (ft) {helpers.requiredStar()}
                                <Input
                                  type="number"
                                  name="widthInFeet"
                                  id="widthInFeet"
                                  onChange={(event) => changeJobField(event)}
                                  value={job.widthInFeet}
                                  className="form-control"
                                  disabled={
                                    (!isJobOwner && !draftMode) || jobComplete
                                  }
                                />
                              </Col>
                              <Col xs="3">
                                Height (ft) {helpers.requiredStar()}
                                <Input
                                  type="number"
                                  name="heightInFeet"
                                  id="heightInFeet"
                                  onChange={(event) => changeJobField(event)}
                                  value={job.heightInFeet}
                                  className="form-control"
                                  disabled={
                                    (!isJobOwner && !draftMode) || jobComplete
                                  }
                                />
                              </Col>
                            </Row>
                            <Row className="mb-2 align-items-end">
                              <Col xs="3">
                                Wall Color {helpers.requiredStar()}
                                <Select
                                  name="wallColor"
                                  id="unitColor"
                                  closeMenuOnSelect={true}
                                  value={job.wallColor}
                                  options={unitColorList}
                                  onChange={(event) =>
                                    changeJobSelect(event, "wallColor")
                                  }
                                  isDisabled={
                                    (!isJobOwner && !draftMode) || jobComplete
                                  }
                                />
                              </Col>
                              <Col xs="3">
                                Roof Color {helpers.requiredStar()}
                                <Select
                                  name="roofColor"
                                  id="roofColor"
                                  closeMenuOnSelect={true}
                                  value={job.roofColor}
                                  options={unitColorList}
                                  onChange={(event) =>
                                    changeJobSelect(event, "roofColor")
                                  }
                                  isDisabled={
                                    (!isJobOwner && !draftMode) || jobComplete
                                  }
                                />
                              </Col>
                              <Col xs="3">
                                Trim Color {helpers.requiredStar()}
                                <Select
                                  name="trimColor"
                                  id="trimColor"
                                  closeMenuOnSelect={true}
                                  value={job.trimColor}
                                  options={unitColorList}
                                  onChange={(event) =>
                                    changeJobSelect(event, "trimColor")
                                  }
                                  isDisabled={
                                    (!isJobOwner && !draftMode) || jobComplete
                                  }
                                />
                              </Col>
                            </Row>
                            <Row className="mb-2 align-items-end">
                              <Col xs="3">
                                {job.wallColor.value ==
                                constants.COLOR_IDS.OTHER ? (
                                  <Input
                                    type="text"
                                    name="otherWallColor"
                                    id="otherWallColor"
                                    onChange={(event) => changeJobField(event)}
                                    value={job.otherWallColor}
                                    className="form-control"
                                    disabled={jobComplete}
                                    maxLength="75"
                                  />
                                ) : null}
                              </Col>
                              <Col xs="3">
                                {job.roofColor.value ==
                                constants.COLOR_IDS.OTHER ? (
                                  <Input
                                    type="text"
                                    name="otherRoofColor"
                                    id="otherRoofColor"
                                    onChange={(event) => changeJobField(event)}
                                    value={job.otherRoofColor}
                                    className="form-control"
                                    disabled={jobComplete}
                                    maxLength="75"
                                  />
                                ) : null}
                              </Col>
                              <Col xs="3">
                                {job.trimColor.value ==
                                constants.COLOR_IDS.OTHER ? (
                                  <Input
                                    type="text"
                                    name="otherTrimColor"
                                    id="otherTrimColor"
                                    onChange={(event) => changeJobField(event)}
                                    value={job.otherTrimColor}
                                    className="form-control"
                                    disabled={jobComplete}
                                    maxLength="75"
                                  />
                                ) : null}
                              </Col>
                            </Row>
                            <Row className="mb-2 mt-5">
                              <Col>
                                {!isJobOwner ? null : (
                                  <Button
                                    size="sm"
                                    className="projectSuccess float-right"
                                    onClick={() => onAddNewFee()}
                                  >
                                    Add New Fee
                                  </Button>
                                )}
                                <h5>Compensation Details</h5>
                              </Col>
                            </Row>
                            <Row className="mb-2 align-items-end">
                              <Col xs="12">
                                <Table
                                  striped
                                  hover
                                  size="sm"
                                  responsive={false}
                                  id="feesTable"
                                >
                                  <thead>
                                    <tr>
                                      <th>Fee Type {helpers.requiredStar()}</th>
                                      <th>
                                        Description {helpers.requiredStar()}
                                      </th>
                                      {jobReserved ? null : (
                                        <Fragment>
                                          <th>Amt {helpers.requiredStar()}</th>
                                          <th width="8%" />
                                        </Fragment>
                                      )}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {newFee ? <tr>{newJobFeeRow()}</tr> : null}
                                    {jobFees &&
                                      jobFees.length > 0 &&
                                      _.map(jobFees, (jf, index) => (
                                        <tr key={`jobfees-${index}`}>
                                          {jf.isEditing ? (
                                            jobFeeRow(jf, index)
                                          ) : (
                                            <React.Fragment>
                                              <td style={{ width: "20%" }}>
                                                {jf.feeType
                                                  ? jf.feeType.label
                                                  : null}
                                              </td>
                                              <td style={{ width: "30%" }}>
                                                {jf.description}
                                              </td>
                                              <td>
                                                {helpers.formatCurrency(
                                                  jf.amount || 0
                                                )}
                                              </td>
                                              {jobReserved || isHaulerUser ? (
                                                <td />
                                              ) : (
                                                <td>
                                                  <ButtonGroup className="float-right">
                                                    <Button
                                                      size="sm"
                                                      className="projectInfo"
                                                      onClick={() =>
                                                        onEditJobFee(
                                                          jf.local_id
                                                        )
                                                      }
                                                      // disabled={}
                                                    >
                                                      <FontAwesomeIcon icon="edit" />
                                                    </Button>
                                                    {/* {jf.deactivatedAt ? (
                                                      <Button
                                                        size="sm"
                                                        className="projectInfo minWidth105"
                                                        // onClick={() => this.togglminWidth150eActive(u, "activate")}
                                                      >
                                                        <FontAwesomeIcon icon="recycle" />{" "}
                                                        Revive
                                                      </Button>
                                                    ) : ( */}
                                                    <Button
                                                      size="sm"
                                                      className="projectDanger"
                                                      onClick={() =>
                                                        onDeleteJobFee(
                                                          jf.local_id,
                                                          jf.amount
                                                        )
                                                      }
                                                      title="Remove from quote"
                                                    >
                                                      <FontAwesomeIcon icon="times-circle" />
                                                    </Button>
                                                    {/* )} */}
                                                  </ButtonGroup>
                                                </td>
                                              )}
                                            </React.Fragment>
                                          )}
                                        </tr>
                                      ))}
                                    <tr
                                      style={{
                                        backgroundColor: "white",
                                        borderTop: "1px black",
                                      }}
                                    >
                                      <td />
                                      <td>
                                        <div
                                          className="float-right mr-3"
                                          style={{ fontWeight: "bold" }}
                                        >
                                          Total:
                                        </div>
                                      </td>
                                      <td>
                                        {helpers.formatCurrency(totalFees)}
                                      </td>
                                      <td />
                                    </tr>
                                  </tbody>
                                </Table>
                              </Col>
                            </Row>
                            {isJobOwner && ( //&& (draftMode || jobOpen)
                              <Fragment>
                                <Row className="mb-1 mt-4">
                                  <Col xs="8">
                                    <h5>Checklist Items To Include</h5>
                                  </Col>
                                  {console.log("Draft Mod:  ", draftMode)}
                                  {console.log("Job Open:  ", jobOpen)}
                                  {(draftMode || jobOpen) && (
                                    <Fragment>
                                      <Col
                                        xs="1"
                                        className="mt-2 text-right pr-0"
                                      >
                                        Add Item:
                                      </Col>
                                      <Col>
                                        <Select
                                          closeMenuOnSelect={true}
                                          options={orgDefaultChecklistItems}
                                          value={null}
                                          onChange={(event) =>
                                            onAddJobChecklistItem(event)
                                          }
                                          isDisabled={!draftMode && !jobOpen}
                                        />
                                      </Col>
                                    </Fragment>
                                  )}
                                </Row>
                                <Row className="mb-2 align-items-end">
                                  <Col xs="12">
                                    <Table
                                      striped
                                      hover
                                      size="sm"
                                      responsive={false}
                                      id="feesTable"
                                    >
                                      <thead>
                                        <tr>
                                          <th>Item</th>
                                          <th>Type</th>
                                          <th>Required</th>
                                          <th width="8%" />
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {jobChecklistItems &&
                                          jobChecklistItems.length > 0 &&
                                          _.map(jobChecklistItems, (item) => (
                                            <tr key={item.local_id}>
                                              <td>
                                                {item.checkListItem
                                                  ? item.checkListItem.prompt
                                                  : ""}
                                              </td>
                                              <td>
                                                {item.checkListItem
                                                  ? item.checkListItem
                                                      .checkListItemTypeName
                                                  : ""}
                                              </td>
                                              <td>
                                                {item.checkListItem &&
                                                item.checkListItem
                                                  .isRequired ? (
                                                  <FontAwesomeIcon
                                                    icon="check"
                                                    className="text-success"
                                                  />
                                                ) : null}
                                              </td>
                                              <td>
                                                {draftMode || jobOpen ? (
                                                  <Button
                                                    size="sm"
                                                    className="projectDanger float-right"
                                                    onClick={() =>
                                                      onRemoveJobChecklistItem(
                                                        item.local_id
                                                      )
                                                    }
                                                    title="Remove from quote"
                                                  >
                                                    <FontAwesomeIcon icon="times-circle" />
                                                  </Button>
                                                ) : null}
                                              </td>
                                            </tr>
                                          ))}
                                      </tbody>
                                    </Table>
                                  </Col>
                                </Row>
                              </Fragment>
                            )}
                            {(isJobOwner ||
                              currentUser.currentOrgId ===
                                job?.reservedByOrg?.value) && (
                              <Col xs="12" className="mx-0 px-0">
                                <JobDocumentUpload
                                  cardName={helpers.cardHeaderName(
                                    "Documents",
                                    "upload"
                                  )}
                                  newDocumentsToUpload={newDocumentsToUpload}
                                  setNewDocumentsToUpload={
                                    setNewDocumentsToUpload
                                  }
                                  existingDocuments={job.documents}
                                  uploadUrl={`Job/SaveJobDocument`}
                                  deleteUrl={`Job/DeleteJobDocument`}
                                  jobId={job.id}
                                  refresh={getJobDocuments}
                                />
                              </Col>
                            )}

                            {(isJobOwner ||
                              currentUser.currentOrgId ===
                                job?.reservedByOrg?.value) && (
                              <Fragment>
                                <Row className="mb-2 mt-4">
                                  <Col>
                                    <Button
                                      size="sm"
                                      className="projectSuccess float-right"
                                      onClick={() => onAddNewComment()}
                                    >
                                      Add New Comment
                                    </Button>
                                    <h5>Comments</h5>
                                  </Col>
                                </Row>
                                <Row className="mb-2 align-items-end">
                                  <Col xs="12">
                                    <Table
                                      striped
                                      hover
                                      size="sm"
                                      responsive={false}
                                      id="feesTable"
                                    >
                                      <thead>
                                        <tr>
                                          <th>Comment</th>
                                          <th>Is Private?</th>
                                          <th width="8%" />
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {newComment ? (
                                          <tr>{newCommentRow()}</tr>
                                        ) : null}
                                        {comments &&
                                          comments.length > 0 &&
                                          _.map(comments, (c, index) => (
                                            <tr key={c.local_id}>
                                              {c.isEditing ? (
                                                jobCommentRow(c, index)
                                              ) : (
                                                <React.Fragment>
                                                  <td style={{ width: "60%" }}>
                                                    {c.comment}
                                                  </td>
                                                  <td>
                                                    <div className="ml-2">
                                                      {c.isPrivate ? (
                                                        <span className="text-success">
                                                          <FontAwesomeIcon icon="check" />
                                                        </span>
                                                      ) : null}
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <ButtonGroup className="float-right">
                                                      <Button
                                                        size="sm"
                                                        className="projectInfo"
                                                        onClick={() =>
                                                          onEditComment(
                                                            c.local_id
                                                          )
                                                        }
                                                        // disabled={}
                                                      >
                                                        <FontAwesomeIcon icon="edit" />
                                                      </Button>
                                                      <Button
                                                        size="sm"
                                                        className="projectDanger"
                                                        onClick={() =>
                                                          onDeleteComment(
                                                            c.local_id
                                                          )
                                                        }
                                                        title="Remove from quote"
                                                      >
                                                        <FontAwesomeIcon icon="times-circle" />
                                                      </Button>
                                                    </ButtonGroup>
                                                  </td>
                                                </React.Fragment>
                                              )}
                                            </tr>
                                          ))}
                                      </tbody>
                                    </Table>
                                  </Col>
                                </Row>
                              </Fragment>
                            )}
                            {isJobOwner && !isHaulerFirm && (
                              <Fragment>
                                <Row className="mb-2 mt-4">
                                  <Col xs="12">
                                    <h5>Invite Specific Hauler</h5>
                                  </Col>
                                </Row>
                                <Row className="mb-5 align-items-end">
                                  <Col xs="4">
                                    Hauling Co
                                    <OrgTypeahead
                                      onChange={(e) => onReserveHaulerChange(e)} //setJob({ ...job, reservedByOrg: e })}
                                      currentOrgId={currentUser.currentOrgId}
                                      value={job.reservedByOrg}
                                      orgTypeId={
                                        constants.ORG_TYPE_IDS.HAULER_FIRM
                                      }
                                      isClearable={true}
                                      isDisabled={
                                        jobReserved ||
                                        jobScheduled ||
                                        jobInProgress ||
                                        jobComplete ||
                                        jobCancelled
                                      }
                                    />
                                  </Col>
                                  <Col xs="4">
                                    Driver{" "}
                                    <span className="information-small">
                                      (Optional)
                                    </span>
                                    <Select
                                      closeMenuOnSelect={true}
                                      value={job.driver}
                                      options={driverOptions}
                                      onChange={(event) =>
                                        setJob({ ...job, driver: event })
                                      }
                                      placeholder="Not Specified"
                                      isClearable
                                      isDisabled={
                                        jobReserved ||
                                        jobScheduled ||
                                        jobInProgress ||
                                        jobComplete ||
                                        jobCancelled
                                      }
                                    />
                                  </Col>
                                  {/* <Col xs="4">
                                  <ButtonGroup className="float-right">
                                    <Button
                                      className="projectPrimary btn btn-primary"
                                      onClick={() => sendHaulerInvite()}
                                      disabled={!(job.reservedByOrg)} // && job.driver //
                                    >
                                      Send Invite to this Hauler
                                    </Button>
                                  </ButtonGroup>
                                </Col> */}
                                </Row>
                              </Fragment>
                            )}
                            {(isJobOwner ||
                              currentUser.currentOrgId ===
                                job?.reservedByOrg?.value) && (
                              <Row className="mb-2 align-items-end">
                                <Col>
                                  <ButtonGroup className="float-right">
                                    {isJobOwner && (
                                      <Button
                                        color="secondary"
                                        tag={Link}
                                        to={"/jobs"}
                                      >
                                        Cancel
                                      </Button>
                                    )}
                                    <Button
                                      className="projectPrimary btn btn-primary"
                                      onClick={() => onSaveJob()}
                                    >
                                      {isJobOwner
                                        ? "Save Job"
                                        : "Save Comments"}
                                    </Button>
                                  </ButtonGroup>
                                </Col>
                              </Row>
                            )}
                          </Fragment>
                        }
                      />
                    </Col>
                  </Row>
                  {jobReserved ||
                  jobScheduled ||
                  jobInProgress ||
                  jobComplete ? (
                    <Row className="mb-2">
                      <Col xs="12" className="mx-0 px-0">
                        <CollapsibleCardSection
                          cardName={"Hauler Info"}
                          startOpen={true}
                          cardBody={
                            <Fragment>
                              <Row className="mb-2 align-items-end">
                                <Col xs="12">
                                  <Row>
                                    <Col xs="12">
                                      <h5>
                                        Hauler Org: {job?.reservedByOrg?.label}
                                      </h5>
                                    </Col>
                                  </Row>
                                  <Row className="mb-3">
                                    <Col xs="3">
                                      Contact
                                      <Input
                                        value={`${job.reservedByOrgPrimaryContactName}`}
                                        readOnly
                                      />
                                    </Col>
                                    <Col xs="3">
                                      Ph #
                                      <Input
                                        value={
                                          job.reservedByOrgPrimaryContactNumber
                                        }
                                        readOnly
                                      />
                                    </Col>
                                    <Col xs="3" />
                                  </Row>
                                </Col>
                              </Row>
                              {(jobReserved && isHaulerUser) ||
                              jobScheduled ||
                              jobInProgress ||
                              jobComplete ? (
                                <Fragment>
                                  <Row>
                                    <Col xs="12">
                                      <h5>Schedule</h5>
                                    </Col>
                                  </Row>
                                  <Row className="mb-3">
                                    <Col xs="4">
                                      Driver{" "}
                                      {!jobComplete && helpers.requiredStar()}
                                      <Select
                                        closeMenuOnSelect={true}
                                        options={driverOptions}
                                        onChange={(event) =>
                                          changeJobSelect(event, "driver")
                                        }
                                        placeholder="Not specified"
                                        isDisabled={
                                          !isHaulerUser ||
                                          jobInProgress ||
                                          jobComplete
                                        }
                                        value={job.driver}
                                      />
                                    </Col>
                                    <Col xs="2">
                                      Pickup Date{" "}
                                      {!jobComplete && helpers.requiredStar()}
                                      <div className="customDatePickerWidth">
                                        <DatePicker
                                          selected={job.estimatedPickupAt}
                                          onChange={(selected) =>
                                            onDateChange(
                                              selected,
                                              "estimatedPickupAt"
                                            )
                                          }
                                          isClearable={isHaulerFirm}
                                          className="form-control"
                                          readOnly={
                                            !isHaulerUser ||
                                            jobInProgress ||
                                            jobComplete
                                          }
                                        />
                                      </div>
                                    </Col>
                                    <Col xs="2">
                                      {" "}
                                      Est Pickup Time Of Day
                                      <EstimatedTimeOfDay
                                        value={job.estimatedPickupTimeOfDay}
                                        onChange={(e) =>
                                          setJob({
                                            ...job,
                                            estimatedPickupTimeOfDay:
                                              e.target.value,
                                          })
                                        }
                                        disabled={
                                          !isHaulerUser ||
                                          jobInProgress ||
                                          jobComplete
                                        }
                                      />
                                    </Col>
                                    <Col xs="2">
                                      Dropoff Date{" "}
                                      {!jobComplete && helpers.requiredStar()}
                                      <div className="customDatePickerWidth">
                                        <DatePicker
                                          selected={job.estimatedDropoffAt}
                                          onChange={(selected) =>
                                            onDateChange(
                                              selected,
                                              "estimatedDropoffAt"
                                            )
                                          }
                                          isClearable={isHaulerFirm}
                                          className="form-control"
                                          disabled={jobComplete}
                                          readOnly={
                                            !isHaulerUser ||
                                            jobInProgress ||
                                            jobComplete
                                          }
                                        />
                                      </div>
                                    </Col>
                                    <Col xs="2">
                                      {" "}
                                      Est Dropoff Time Of Day
                                      <EstimatedTimeOfDay
                                        value={job.estimatedDropoffTimeOfDay}
                                        onChange={(e) =>
                                          setJob({
                                            ...job,
                                            estimatedDropoffTimeOfDay:
                                              e.target.value,
                                          })
                                        }
                                        disabled={
                                          !isHaulerUser ||
                                          jobInProgress ||
                                          jobComplete
                                        }
                                      />
                                    </Col>
                                  </Row>
                                  <Row className="mb-3">
                                    <Col className="float-right">
                                      {isHaulerUser &&
                                        (jobReserved || jobScheduled) && (
                                          <Button
                                            // size="sm"
                                            className="projectPrimary float-right"
                                            onClick={() => onScheduleJob()}
                                            title="Save Driver Schedule"
                                          >
                                            <FontAwesomeIcon
                                              icon="save"
                                              className="mr-2"
                                            />{" "}
                                            Save Schedule
                                          </Button>
                                        )}
                                    </Col>
                                  </Row>
                                </Fragment>
                              ) : null}
                              {jobOpen || jobInProgress || jobComplete ? (
                                <Fragment>
                                  <Row>
                                    <Col xs="12">
                                      <h5>Job Progress Details</h5>
                                    </Col>
                                  </Row>
                                  <Row className="mb-3">
                                    <Col xs="2">
                                      Actual Pickup At
                                      <div className="customDatePickerWidth">
                                        <DatePicker
                                          selected={job.actualPickupAt}
                                          onChange={(selected) =>
                                            onDateChange(
                                              selected,
                                              "actualPickupAt"
                                            )
                                          }
                                          isClearable={isHaulerFirm}
                                          className="form-control mr-0 pr-0"
                                          disabled={jobComplete}
                                          readOnly={!isHaulerUser}
                                        />
                                      </div>
                                    </Col>
                                    <Col xs="1">
                                      <input
                                        className="form-control mt-4 mx-0 p-0"
                                        type="time"
                                        id="appt"
                                        name="appt"
                                        min="09:00"
                                        max="18:00"
                                        required
                                        value={job.actualPickupAtTime}
                                        onChange={(selected) =>
                                          onTimeChange(
                                            selected,
                                            "actualPickupAtTime"
                                          )
                                        }
                                      />
                                    </Col>
                                    <Col xs="2">
                                      Actual Dropoff At
                                      <div className="customDatePickerWidth">
                                        <DatePicker
                                          selected={job.actualDropoffAt}
                                          onChange={(selected) =>
                                            onDateChange(
                                              selected,
                                              "actualDropoffAt"
                                            )
                                          }
                                          isClearable={isHaulerFirm}
                                          className="form-control mr-0 pr-0"
                                          disabled={jobComplete}
                                          readOnly={!isHaulerUser}
                                        />
                                      </div>
                                    </Col>
                                    <Col xs="1">
                                      <input
                                        className="form-control mt-4 mx-0 p-0"
                                        type="time"
                                        id="appt"
                                        name="appt"
                                        min="09:00"
                                        max="18:00"
                                        required
                                        value={job.actualDropoffAtTime}
                                        onChange={(selected) =>
                                          onTimeChange(
                                            selected,
                                            "actualDropoffAtTime"
                                          )
                                        }
                                      />
                                    </Col>
                                    <Col xs="2">
                                      Rig Type Used
                                      <Select
                                        closeMenuOnSelect={true}
                                        value={jobRigs}
                                        // values={jobRigs}
                                        // isMulti
                                        options={orgRigs}
                                        onChange={(event) =>
                                          onJobRigChange(event)
                                        }
                                        placeholder="Not specified"
                                        isDisabled={
                                          !isHaulerUser || jobComplete
                                        }
                                      />
                                    </Col>
                                  </Row>
                                  {jobMileageAndFuelCostSummary && (
                                    <Row>
                                      <Col xs="2">
                                        <Button
                                          onClick={() => showMileages()}
                                        >{`Mileage: ${jobMileageAndFuelCostSummary.mileageDisplay}`}</Button>
                                      </Col>
                                      <Col xs="2">
                                        <Button
                                          onClick={() => showFuelCosts()}
                                        >{`Fuel Costs: ${jobMileageAndFuelCostSummary.fuelCostDisplay}`}</Button>
                                      </Col>
                                    </Row>
                                  )}
                                  {showMileageModal && (
                                    <JobSummaryModal
                                      data={mileages}
                                      title="Job Mileage"
                                      onClose={() => setShowMileageModal(false)}
                                      open={showMileageModal}
                                    />
                                  )}
                                  {showFuelCostsModal && (
                                    <JobSummaryModal
                                      data={fuelCosts}
                                      title="Job Fuel Costs"
                                      onClose={() =>
                                        setShowFuelCostsModal(false)
                                      }
                                      open={showFuelCostsModal}
                                    />
                                  )}
                                  <Row className="mb-1 mt-4">
                                    <Col xs="8">
                                      <h5>
                                        {isJobOwner
                                          ? `Checklist Responses`
                                          : `Checklist Items Requested`}
                                      </h5>
                                    </Col>
                                  </Row>
                                  <Row className="mb-2 align-items-end">
                                    <Col xs="12">
                                      <Table
                                        striped
                                        hover
                                        size="sm"
                                        responsive={false}
                                        id="feesTable"
                                      >
                                        <thead>
                                          <tr>
                                            <th>Item</th>
                                            {/* <th>Type</th> */}
                                            <th>Response</th>
                                            <th>Required</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {jobChecklistItems &&
                                            jobChecklistItems.length > 0 &&
                                            _.map(jobChecklistItems, (item) => (
                                              <tr key={item.local_id}>
                                                <td>
                                                  {item.checkListItem
                                                    ? item.checkListItem.prompt
                                                    : ""}
                                                </td>
                                                {/* <td>{item.checkListItem ? item.checkListItem.checkListItemTypeName : ''}</td> */}
                                                <td>
                                                  {jobChecklistResponses &&
                                                  jobChecklistResponses.length ? (
                                                    <CheckListResponse
                                                      clResponse={_.find(
                                                        jobChecklistResponses[0]
                                                          .responseItems,
                                                        (ri) =>
                                                          ri.jobChecklistItemId ===
                                                          item.id
                                                      )}
                                                    />
                                                  ) : (
                                                    <CheckListItem
                                                      jobId={job.id}
                                                      orgId={job.orgId}
                                                      jobChecklistItemId={
                                                        item.id
                                                      }
                                                      clItem={
                                                        item.checkListItem
                                                      }
                                                      checklistAnswers={
                                                        checklistAnswers
                                                      }
                                                      setChecklistAnswers={
                                                        setChecklistAnswers
                                                      }
                                                    />
                                                  )}
                                                </td>
                                                <td>
                                                  {item.required ? (
                                                    <FontAwesomeIcon
                                                      icon="check"
                                                      className="text-success"
                                                    />
                                                  ) : null}
                                                </td>
                                              </tr>
                                            ))}
                                        </tbody>
                                      </Table>
                                    </Col>
                                  </Row>

                                  {(isJobOwner ||
                                    currentUser.currentOrgId ===
                                      job?.reservedByOrg?.value) && (
                                    <Col xs="12" className="mx-0 px-0">
                                      <JobDocumentUpload
                                        cardName={helpers.cardHeaderName(
                                          "Documents",
                                          "upload"
                                        )}
                                        newDocumentsToUpload={
                                          newDocumentsToUpload
                                        }
                                        setNewDocumentsToUpload={
                                          setNewDocumentsToUpload
                                        }
                                        existingDocuments={job.documents}
                                        uploadUrl={`Job/SaveJobDocument`}
                                        deleteUrl={`Job/DeleteJobDocument`}
                                        jobId={job.id}
                                        refresh={getJobDocuments}
                                      />
                                    </Col>
                                  )}
                                  <Row className="mb-3">
                                    {jobInProgress && (
                                      <Col>
                                        <Button
                                          className="projectPrimary float-right"
                                          title="Complete Job"
                                          onClick={() => onCompleteJob()}
                                        >
                                          <FontAwesomeIcon icon="flag-checkered" />{" "}
                                          Complete Job
                                        </Button>
                                      </Col>
                                    )}
                                    {jobComplete && job.invoiced && (
                                      <Col xs="4">
                                        <Button
                                          className="projectPrimary float-right"
                                          onClick={() =>
                                            api.downloadPDF(
                                              "job.FinalInvoicePdfURL"
                                            )
                                          }
                                          // disabled={!draftMode}
                                        >
                                          <FontAwesomeIcon icon="download" />{" "}
                                          Invoice
                                        </Button>
                                      </Col>
                                    )}
                                  </Row>
                                </Fragment>
                              ) : null}
                            </Fragment>
                          }
                        />
                      </Col>
                    </Row>
                  ) : null}
                </DesktopContainer>
              );
          }
        }}
      </Media>
    );
  } else {
    return (
      <SHBSpinner
        on={loading}
        className="spinnerCenter"
        phrases={constants.PHRASES}
      >
        <DesktopContainer screenName={`Edit Job`} />
      </SHBSpinner>
    );
  }
}

const setAnswer = (
  checklistAnswers,
  clItem,
  value,
  setChecklistAnswers,
  jobChecklistItemId
) => {
  if (!clItem) return;
  const newValue = {
    value: value,
    checklistItemTypeId: clItem.checkListItemTypeId,
    documentTypeId: clItem.documentTypeId,
    sequence: clItem.seq,
    prompt: clItem.prompt,
    jobChecklistItemId: jobChecklistItemId,
    isRequired: clItem.isRequired,
  };
  setChecklistAnswers({ ...checklistAnswers, [clItem.id]: newValue });
};

function CheckListItem({
  clItem,
  checklistAnswers,
  setChecklistAnswers,
  jobChecklistItemId,
  jobId,
}) {
  const [addingImage, setAddingImage] = useState(null);

  const ItemWrapper = ({ seq, children }) => {
    return (
      <span>
        {children}
        {/* <span style={{ fontSize: 18, fontWeight: 'bold' }}>{seq}. </span>{children} */}
      </span>
    );
  };

  const value =
    checklistAnswers &&
    checklistAnswers[clItem.id] &&
    (checklistAnswers[clItem.id].value !== null ||
      checklistAnswers[clItem.id].value !== undefined)
      ? checklistAnswers[clItem.id].value
      : "";

  const previewStyle = {
    display: "inline",
    width: 200,
    height: 200,
  };

  const prompt = _.truncate(clItem.prompt, { length: 18 });

  if (
    constants.CHECKLIST_ITEM_TYPE_IDS.ATTACHMENT === clItem.checkListItemTypeId
  ) {
    return (
      <ItemWrapper key={clItem.id} seq={clItem.seq}>
        <div>
          <Button
            className={addingImage ? "danger" : "success"}
            size="sm"
            id="dropzone-toggler"
            onClick={() => setAddingImage(!addingImage)}
          >
            {addingImage ? (
              <FontAwesomeIcon icon="minus" />
            ) : (
              <FontAwesomeIcon icon="plus" />
            )}{" "}
            Images
          </Button>
          {addingImage ? (
            <React.Fragment>
              <Row className="mb-0">
                <Col>
                  <ChecklistImageUpload
                    cardName={helpers.cardHeaderName("Documents", "upload")}
                    // uploadUrl={`Job/SaveJobDocument`}
                    jobId={jobId}
                    documentTypeId={clItem.documentTypeId}
                    orgId={clItem.orgId}
                    jobChecklistItemId={jobChecklistItemId}
                    refresh={(v) =>
                      setAnswer(
                        checklistAnswers,
                        clItem,
                        v,
                        setChecklistAnswers,
                        jobChecklistItemId
                      )
                    }
                  />
                  {value ? (
                    <Card style={{ textAlign: "center" }}>
                      File to be uploaded
                      <CardBody>
                        <Row>
                          <Col>
                            <Row className="mb-2">
                              <Col>
                                <span>
                                  {
                                    <img
                                      alt="Preview"
                                      src={value.preview}
                                      style={previewStyle}
                                    />
                                  }
                                </span>
                              </Col>
                              <Col>
                                <Button
                                  className="danger btn-outline-secondary"
                                  size="sm"
                                  onClick={() =>
                                    setAnswer(
                                      checklistAnswers,
                                      clItem,
                                      null,
                                      setChecklistAnswers,
                                      jobChecklistItemId
                                    )
                                  }
                                >
                                  <FontAwesomeIcon icon="trash" />
                                </Button>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  ) : null}
                </Col>
              </Row>
            </React.Fragment>
          ) : null}
        </div>
      </ItemWrapper>
    );
  }

  if (
    constants.CHECKLIST_ITEM_TYPE_IDS.BOOLEAN === clItem.checkListItemTypeId
  ) {
    return (
      <ItemWrapper key={clItem.id} {...clItem}>
        <Switch
          onChange={(e) =>
            setAnswer(
              checklistAnswers,
              clItem,
              e,
              setChecklistAnswers,
              jobChecklistItemId
            )
          }
          checked={typeof value === "boolean" ? value : false}
        />
      </ItemWrapper>
    );
  }

  if (
    constants.CHECKLIST_ITEM_TYPE_IDS.GPS_COORDINATES ===
    clItem.checkListItemTypeId
  ) {
    return (
      <ItemWrapper key={clItem.id} {...clItem}>
        Log in to driver app to enter.
      </ItemWrapper>
    );
  }

  if (
    constants.CHECKLIST_ITEM_TYPE_IDS.SIGNATURE === clItem.checkListItemTypeId
  ) {
    return (
      <ItemWrapper key={clItem.id} {...clItem}>
        Log in to driver app to enter.
      </ItemWrapper>
    );
  }

  if (constants.CHECKLIST_ITEM_TYPE_IDS.TEXT === clItem.checkListItemTypeId) {
    return (
      <span>
        <Input
          type="text"
          placeholder={prompt}
          value={value}
          onChange={(e) =>
            setAnswer(
              checklistAnswers,
              clItem,
              e.target.value,
              setChecklistAnswers,
              jobChecklistItemId
            )
          }
        />
      </span>
    );
  }

  if (
    constants.CHECKLIST_ITEM_TYPE_IDS.PAYMENT === clItem.checkListItemTypeId
  ) {
    return (
      <ItemWrapper key={clItem.id} {...clItem}>
        Log in to driver app to enter.
      </ItemWrapper>
    );
  }
  console.warn("No matching render item for CheckListItemType of " + clItem.id);
  return null;
}

function CheckListResponse({ clResponse }) {
  if (!clResponse) {
    return null;
  }

  const ResponseWrapper = ({ children }) => {
    return <div key={clResponse.jobChecklistItemId}>{children}</div>;
  };

  if (
    constants.CHECKLIST_ITEM_TYPE_IDS.ATTACHMENT ===
    clResponse.checkListItemTypeId
  ) {
    return (
      <ResponseWrapper>
        {clResponse.attachmentResponseSignedUrl ? (
          <React.Fragment>
            <Row>
              <Col>
                <Button
                  className="primary"
                  size="sm"
                  title="View Document"
                  onClick={() =>
                    window.open(
                      clResponse.attachmentResponseSignedUrl ||
                        clResponse.attachmentResponseSignedUrl,
                      "_blank"
                    )
                  }
                >
                  <FontAwesomeIcon icon="external-link-alt" />
                </Button>
              </Col>
            </Row>
          </React.Fragment>
        ) : (
          <div>Attachment</div>
        )}
      </ResponseWrapper>
    );
  }

  if (
    constants.CHECKLIST_ITEM_TYPE_IDS.BOOLEAN === clResponse.checkListItemTypeId
  ) {
    return (
      <ResponseWrapper>
        <div>
          {_.isBoolean(clResponse.boolResponse)
            ? clResponse.boolResponse
              ? "Yes"
              : "No"
            : "N/A"}
        </div>
      </ResponseWrapper>
    );
  }

  if (
    constants.CHECKLIST_ITEM_TYPE_IDS.GPS_COORDINATES ===
    clResponse.checkListItemTypeId
  ) {
    return (
      <ResponseWrapper>
        <div>
          {` ${clResponse.gpsResponseLat}`}, {clResponse.gpsResponseLong}
        </div>
      </ResponseWrapper>
    );
  }

  if (
    constants.CHECKLIST_ITEM_TYPE_IDS.SIGNATURE ===
    clResponse.checkListItemTypeId
  ) {
    return (
      <ResponseWrapper>
        {clResponse.attachmentResponseSignedUrl ? (
          <Button
            className="primary"
            size="sm"
            title="Signature Link"
            onClick={() =>
              window.open(
                clResponse.attachmentResponseSignedUrl ||
                  clResponse.attachmentResponseSignedUrl,
                "_blank"
              )
            }
          >
            <FontAwesomeIcon icon="external-link-alt" />
          </Button>
        ) : (
          <div>File not found</div>
        )}
      </ResponseWrapper>
    );
  }

  if (
    constants.CHECKLIST_ITEM_TYPE_IDS.TEXT === clResponse.checkListItemTypeId
  ) {
    return (
      <ResponseWrapper>
        <div>{`${clResponse.textResponse}`}</div>
      </ResponseWrapper>
    );
  }
  console.warn(
    "No matching render item for CheckListItemType of " + clResponse.id
  );
  return null;
}
